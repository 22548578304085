import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { STOP_LOSS_PERCENT, TAKE_PROFIT_PERCENT } from 'components/MarginTradingBox/constant';
import { KeyTypeOrderMarginTrading, MarginTrading, PositionHistory } from './type';
import { Side } from '@oraichain/oraimargin-contracts-sdk';

export const initialState: MarginTrading = {
  principal: '',
  principalPercent: 0,
  leverage: '1',
  typeMargin: 'buy',
  typeOrder: KeyTypeOrderMarginTrading.market,
  takeProfit: '',
  takeProfitPercent: TAKE_PROFIT_PERCENT[0],
  stopLoss: '',
  stopLossPercent: STOP_LOSS_PERCENT[0],
  slippagePercent: '0',
  openPositions: [],
  positionHistory: [],
  isRefreshUserPosition: false,
  entryPrice: 0,
  isIgnoreStopLoss: false,
  isNewEventEmitted: false,
  baseAssetLimit: '0',
  vammDecimal: '1000000',
  cumulativePremiumFraction: '0',
  maxLeverage: 1,
};

const marginTradingSlice = createSlice({
  name: 'marginTrading',
  initialState,
  reducers: {
    updatePrincipal: (state, action: PayloadAction<string>) => {
      state.principal = action.payload;
    },
    updatePrincipalPercent: (state, action: PayloadAction<number>) => {
      state.principalPercent = action.payload;
    },
    updateLeverage: (state, action: PayloadAction<string>) => {
      state.leverage = action.payload;
    },
    updateTypeMargin: (state, action: PayloadAction<Side>) => {
      state.typeMargin = action.payload;
    },
    updateTypeOrder: (state, action: PayloadAction<KeyTypeOrderMarginTrading>) => {
      state.typeOrder = action.payload;
    },
    updateTakeProfit: (state, action: PayloadAction<string>) => {
      state.takeProfit = action.payload;
    },
    updateTakeProfitPercent: (state, action: PayloadAction<number>) => {
      state.takeProfitPercent = action.payload;
    },
    updateStopLoss: (state, action: PayloadAction<string>) => {
      state.stopLoss = action.payload;
    },
    updateStopLossPercent: (state, action: PayloadAction<number>) => {
      state.stopLossPercent = action.payload;
    },
    updateSlippagePercent: (state, action: PayloadAction<string>) => {
      state.slippagePercent = action.payload;
    },
    updatePositions: (state, action: PayloadAction<Array<any>>) => {
      state.openPositions = action.payload;
    },
    updatePositionHistory: (state, action: PayloadAction<Array<PositionHistory>>) => {
      state.positionHistory = action.payload;
    },
    setIsRefreshUserPosition: (state, action: PayloadAction<boolean>) => {
      state.isRefreshUserPosition = action.payload;
    },
    updateEntryPrice: (state, action: PayloadAction<number>) => {
      state.entryPrice = action.payload;
    },
    updateBaseAssetLimit: (state, action: PayloadAction<string>) => {
      state.baseAssetLimit = action.payload;
    },
    updateIsNewEventEmitted: (state, action: PayloadAction<boolean>) => {
      state.isNewEventEmitted = action.payload;
    },
    updateVammDecimal: (state, action: PayloadAction<string>) => {
      state.vammDecimal = action.payload;
    },
    updateMaxLeverage: (state, action: PayloadAction<number>) => {
      state.maxLeverage = action.payload;
    },
    updateCumulativePremiumFraction: (state, action: PayloadAction<string>) => {
      state.cumulativePremiumFraction = action.payload;
    },
  },
});

export const {
  updatePrincipal,
  updateLeverage,
  updateTypeMargin,
  updateTypeOrder,
  updatePrincipalPercent,
  updateTakeProfit,
  updateTakeProfitPercent,
  updateStopLoss,
  updateStopLossPercent,
  updateSlippagePercent,
  updatePositions,
  updatePositionHistory,
  setIsRefreshUserPosition,
  updateEntryPrice,
  updateBaseAssetLimit,
  updateIsNewEventEmitted,
  updateVammDecimal,
  updateCumulativePremiumFraction,
  updateMaxLeverage,
} = marginTradingSlice.actions;

export const selectPrincipal = (state: RootState): string => state.marginTrading.principal;
export const selectPrincipalPercent = (state: RootState): number => state.marginTrading.principalPercent;
export const selectLeverage = (state: RootState): string => state.marginTrading.leverage;
export const selectTypeMargin = (state: RootState): Side => state.marginTrading.typeMargin;
export const selectTypeOrder = (state: RootState): string => state.marginTrading.typeOrder;
export const selectTakeProfit = (state: RootState): string => state.marginTrading.takeProfit;
export const selectTakeProfitPercent = (state: RootState): number => state.marginTrading.takeProfitPercent;
export const selectStopLoss = (state: RootState): string => state.marginTrading.stopLoss;
export const selectStopLossPercent = (state: RootState): number => state.marginTrading.stopLossPercent;
export const selectSlippagePercent = (state: RootState): string => state.marginTrading.slippagePercent;
export const selectIsRefreshUserPosition = (state: RootState): boolean => state.marginTrading.isRefreshUserPosition;
export const selectEntryPrice = (state: RootState): number => state.marginTrading.entryPrice;
export const selectPositions = (state: RootState): Array<any> => state.marginTrading.openPositions;
export const selectPositionHistory = (state: RootState): Array<PositionHistory> => state.marginTrading.positionHistory;
export const selectBaseAssetLimit = (state: RootState): string => state.marginTrading.baseAssetLimit;
export const selectIsNewEventEmitted = (state: RootState): boolean => state.marginTrading.isNewEventEmitted;
export const selectVammDecimal = (state: RootState): string => state.marginTrading.vammDecimal;
export const selectMaxLeverage = (state: RootState): number => state.marginTrading.maxLeverage;
export const selectCumulativePremiumFraction = (state: RootState): string =>
  state.marginTrading.cumulativePremiumFraction;

export default marginTradingSlice.reducer;
