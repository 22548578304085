import { oraichainNetwork } from 'config/chainInfos';
import { ROOT_ENV } from 'env';

/* oraiswap:unit */
export const ORAI = 'orai';
export const INJ = 'inj';
export const UAIRI = 'uAIRI';
export const AIRI = 'AIRI';
export const ATOM = 'ATOM';
export const OSMO = 'OSMO';
export const LP = 'LP';
export const KWT = 'oraie';
export const MILKY = 'milky';
export const STABLE_DENOM = 'usdt';
export const TRON_DENOM = 'trx';

export const COSMOS_DECIMALS = 6;

export const networkInfo = oraichainNetwork;

// estimate fee
export const MULTIPLIER = 1.6;
export const HIGH_GAS_PRICE = 0.007;
export const GAS_ESTIMATION_ORDERBOOK_DEFAULT = 200000;
export const GAS_ESTIMATION_SWAP_DEFAULT = 580000;

/* network:settings */
export const AXIOS_THROTTLE_THRESHOLD = 2000;
export const AXIOS_TIMEOUT = 10000;

export const ORAI_SCAN = 'https://scan.orai.io';

// websocket consts
export const WEBSOCKET_RECONNECT_ATTEMPTS = 5;
export const WEBSOCKET_RECONNECT_INTERVAL = 20000;

export const BASE_API_URL = ROOT_ENV.REACT_APP_BASE_API_URL;
export const BASE_STAGING_API_URL = ROOT_ENV.REACT_APP_BASE_STAGING_API_URL;

export const ORDERBOOK_CONTRACT = ROOT_ENV.REACT_APP_ORDERBOOK_CONTRACT;

export const ORDERBOOK_POSITION_CONTRACT = ROOT_ENV.REACT_APP_ORDERBOOK_CONTRACT;

export const ENGINE_CONTRACT = ROOT_ENV.REACT_APP_PERPETUAL_ENGINE_CONTRACT;

// commission_rate pool
export const COMMISSION_RATE = '0.003';

export const ORAI_INFO = {
  native_token: {
    denom: ORAI,
  },
};

export const VAMM_PRICE_QUERY_INTERVAL = 15000; // in ms

export const TIMER = {
  HAFT_MILLISECOND: 500,

  MILLISECOND: 1000,
  SECOND: 60,
  MINUTE: 60,
  HOUR: 24,

  MIN_SYSTEM_YEAR: 1971,
};
