import React, { useMemo, memo, useState } from 'react';
import cn from 'classnames/bind';
import Button from 'components/Button';
import useConfigReducer from 'hooks/useConfigReducer';
import { useSelector } from 'react-redux';
import { selectAmounts } from 'redux/reducers/tokenSlice';
import { selectCurrentToken, selectListToken } from 'redux/reducers/tradingSlice';
import { getCoingeckoIdWithDenom, getDecimalWithDenom, getKeyAmountWithDenom } from 'helpers/orderbook';
import { getOraiWithToken, toDisplay } from 'libs/utils';
import CosmJs from 'libs/cosmjs';
import { ORAI } from 'config/constants';
import { fetchTokenInfos, simulateSwap } from 'rest/api';
import { TToastType, displayToast } from 'components/Toasts';
import { network } from 'config/networks';
import { handleErrorTransaction } from 'helpers';
import { remapTokenByAddress } from 'config/bridgeTokens';
import CheckBox from 'components/CheckBox';
import { useCoinGeckoPrices } from 'hooks/useCoingecko';
import { roundWithDecimalPlaces } from 'libs/utils';
import useLoadTokens from 'hooks/useLoadTokens';

import { generateMsgsSwap } from '../helpers';
import { MINIMUM_SWAP } from '..';
import styles from '../index.module.scss';

const cx = cn.bind(styles);

const SelectSwapTokenUI: React.FC<{}> = () => {
  const [address] = useConfigReducer('address');
  const amounts: AmountDetails = useSelector(selectAmounts);
  const currentPair = useSelector(selectCurrentToken);
  const listToken = useSelector(selectListToken);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [pairCheckMark, setPairCheckMark] = useState<{ [key: string]: boolean }>({});
  const { data: prices } = useCoinGeckoPrices();
  const loadTokenAmounts = useLoadTokens();

  const tokenSwapInfos = useMemo(() => {
    const pairCheckMarkTemp: { [key: string]: boolean } = {};

    const result = listToken
      .map((token) => {
        const decimals = getDecimalWithDenom(token?.to);
        const balance = amounts[getKeyAmountWithDenom(token?.to)] || '0';
        const amount = toDisplay(balance, decimals);
        const estimated = getOraiWithToken({
          amount: Number(amount),
          coinGeckoId: getCoingeckoIdWithDenom(currentPair?.to),
          prices,
        });

        const tokensHashmapByAddress = remapTokenByAddress();

        if (amount === 0) return;

        pairCheckMarkTemp[token?.to] = false;

        return {
          name: token?.to,
          amount,
          decimals,
          balance,
          estimated,
          tokenName: tokensHashmapByAddress[token?.to].name,
          Icon: tokensHashmapByAddress[token?.to].Icon,
        };
      })
      .filter((token) => token && MINIMUM_SWAP >= token.amount);

    setPairCheckMark(pairCheckMarkTemp);

    return result;
  }, [listToken, amounts]);

  const summary = useMemo(() => {
    const tokenSwapInfosChecked = tokenSwapInfos.filter((e) => pairCheckMark[e.name]);
    const count = tokenSwapInfosChecked.length;
    const estimated = tokenSwapInfosChecked.reduce((prev, curr) => prev + curr.estimated, 0);

    return { count, estimated };
  }, [pairCheckMark, tokenSwapInfos]);

  const isCheckedAll = useMemo(() => {
    return Object.values(pairCheckMark).every(Boolean);
  }, [pairCheckMark]);

  const toggleCheckAll = () => {
    const pairCheckMarkTemp = {};
    if (Object.values(pairCheckMark).every(Boolean)) {
      tokenSwapInfos.forEach((token) => {
        pairCheckMarkTemp[token.name] = false;
      });
      setPairCheckMark(pairCheckMarkTemp);
      return;
    }
    tokenSwapInfos.forEach((token) => {
      pairCheckMarkTemp[token.name] = true;
    });
    setPairCheckMark(pairCheckMarkTemp);
  };

  const handleSwaps = async () => {
    if (!Object.values(pairCheckMark).some(Boolean)) return;

    setIsSwapLoading(true);
    displayToast(TToastType.TX_BROADCASTING);

    const tokensHashmapByAddress = remapTokenByAddress();

    try {
      const messengers = tokenSwapInfos
        .filter((token) => pairCheckMark[token.name])
        .map(async (tokenSwapInfo) => {
          const fromToken = tokensHashmapByAddress[tokenSwapInfo.name];
          const toToken = tokensHashmapByAddress[ORAI];

          const [fromTokenInfoData, toTokenInfoData] = await fetchTokenInfos([fromToken, toToken]);

          const simulate = await simulateSwap({
            fromInfo: fromTokenInfoData,
            toInfo: toTokenInfoData,
            amount: tokenSwapInfo.balance,
          });

          const message = generateMsgsSwap(
            fromTokenInfoData,
            tokenSwapInfo.amount,
            toTokenInfoData,
            amounts,
            simulate,
            1, // slippage default
            address
          );
          return message;
        });

      const msgs = (await Promise.all(messengers)).flat();

      const result = await CosmJs.executeMultiple({
        msgs,
        prefix: ORAI,
        walletAddr: address,
        gasAmount: { denom: ORAI, amount: '0' },
      });
      if (result) {
        displayToast(TToastType.TX_SUCCESSFUL, {
          customLink: `${network.explorer}/txs/${result.transactionHash}`,
        });
      }
      loadTokenAmounts(address);
    } catch (error) {
      handleErrorTransaction(error);
      console.error('error', error);
    } finally {
      setIsSwapLoading(false);
    }
  };

  return (
    <>
      <div className={cx('select-wrap-token-header')}>CONVERT SMALL TOKEN TO ORAI</div>
      <div className={cx('tokens')}>
        <div className={cx('title')}>
          <div className={cx('coin')}>Coin</div>
          <div className={cx('available')}>Available</div>
          <div className={cx('estimated')}>Estimated ORAI amount</div>
        </div>
        <div className={cx('content')}>
          {tokenSwapInfos.map((token) => {
            const { Icon } = token;
            return (
              <div className={cx('token-item')}>
                <div className={cx('coin')}>
                  <CheckBox
                    checked={pairCheckMark[token.name]}
                    onCheck={() => {
                      setPairCheckMark({ ...pairCheckMark, [token.name]: !pairCheckMark[token.name] });
                    }}
                  />
                  <div className={cx('name-container')}>
                    <Icon width={20} height={20} />
                    <span className={cx('name')}>{token.tokenName}</span>
                  </div>
                </div>
                <div className={cx('available')}>{token.amount}</div>
                <div className={cx('estimated')}>{roundWithDecimalPlaces(token.estimated, 6)}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={cx('select-wrap-token-footer')}>
        <div className={cx('summary')}>
          <div className={cx('check-all')}>
            <CheckBox checked={isCheckedAll} onCheck={toggleCheckAll} label={`All (${summary.count} select)`} />
          </div>
          <div className={cx('total')}>
            Estimated ORAI amount <span>{roundWithDecimalPlaces(summary.estimated, 6)} ORAI</span>
          </div>
        </div>
        <div className={cx('submit-btn')}>
          <Button onClick={handleSwaps} loading={isSwapLoading}>
            Exchange to ORAI
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(SelectSwapTokenUI);
