import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { PERSIST_CONFIG_KEY } from 'redux/constants';
import configReducer from './configSlice';
import orderbookReducer from './orderbookSlice';
import tokenReducer from './tokenSlice';
import marginTradingReducer from './marginTradingSlice';
import tradingReducer from './tradingSlice';
import walletReducer from './wallet';
import { leaderboardSlice } from './leaderboardSlice';

const rootPersistConfig = {
  key: PERSIST_CONFIG_KEY,
  storage,
};

const storageReducer = combineReducers({
  config: configReducer,
  token: tokenReducer,
  leaderboard: leaderboardSlice.reducer,
  wallet: walletReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, storageReducer);

const rootReducer = combineReducers({
  orderbook: orderbookReducer,
  trading: tradingReducer,
  persistStorage: persistedReducer,
  marginTrading: marginTradingReducer,
});

export default rootReducer;
