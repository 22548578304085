import useWindowSize from 'hooks/useWindowSize';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import Header from './Header';
import ConnectWallet from './Header/ConnectWallet';
import useTheme from 'hooks/useTheme';
import useConfigReducer from 'hooks/useConfigReducer';
import RenderWhen from 'components/RenderWhen';
import { ROUTES } from 'routes/constant';
import { setCurrentToken, setListToken, setListTokenFilterInitial } from 'redux/reducers/tradingSlice';
import { useDispatch } from 'react-redux';
// import TopRanking from './Header/TopRanking';
import { useLocation } from 'react-router-dom';
import SelectSwapTokenWrapper from './Header/SelectSwapToken';
import { ROOT_ENV } from 'env';
import { BTC_CONTRACT } from '@oraichain/oraidex-common';

const IGNORE_HEADER_ROUTES = [ROUTES.RANKING];

export const DATA_PAIRS = [
  {
    id: 2,
    symbol: 'ORAI/USDC',
    route_key: '/ORAI_USDC',
    slippage: '0.0000000000000000',
    from: 'orai',
    to: ROOT_ENV.REACT_APP_USDC_CONTRACT,
    price_decimal: 6,
    price_decimal_round: 2,
    info: 'orai - ' + ROOT_ENV.REACT_APP_USDC_CONTRACT,
    vamm_contract_address: 'orai1hgc4tmvuj6zuagyjpjjdrgwzj6ncgclm0n6rn4vwjg3wdxxyq0fs9k3ps9',
  },
  {
    id: 3,
    symbol: 'INJ/USDC',
    route_key: '/INJ_USDC',
    slippage: '0.0000000000000000',
    from: ROOT_ENV.INJECTIVE_ORAICHAIN_DENOM,
    to: ROOT_ENV.REACT_APP_USDC_CONTRACT,
    price_decimal: 6,
    price_decimal_round: 2,
    info: ROOT_ENV.INJECTIVE_ORAICHAIN_DENOM + ' - ' + ROOT_ENV.REACT_APP_USDC_CONTRACT,
    vamm_contract_address: 'orai1rujsndzwez98c9wg8vfp0fcjfeprddnlud5dweesd3j0qume9nzqvs0ykn',
    start_time_for_candle: 1703732000, // HARDCODE for filter old vamm data
  },
  {
    id: 4,
    symbol: 'BTC/USDC',
    route_key: '/BTC_USDC',
    slippage: '0.0000000000000000',
    from: BTC_CONTRACT,
    to: ROOT_ENV.REACT_APP_USDC_CONTRACT,
    price_decimal: 6,
    price_decimal_round: 3,
    info: BTC_CONTRACT + ' - ' + ROOT_ENV.REACT_APP_USDC_CONTRACT,
    vamm_contract_address: 'orai13ma2kawhdhtec9vg75h35wnvtsvmsse8wpltt28st2zyevgwnceqc806jq',
  },
  {
    id: 5,
    symbol: 'TON/USDC',
    route_key: '/TON_USDC',
    slippage: '0.0000000000000000',
    from: 'TON_CONTRACT',
    to: ROOT_ENV.REACT_APP_USDC_CONTRACT,
    price_decimal: 6,
    price_decimal_round: 3,
    info: 'TON_CONTRACT' + ' - ' + ROOT_ENV.REACT_APP_USDC_CONTRACT,
    vamm_contract_address: 'orai1uclpn306nv45azqswevrgydv6x775clrwkk790jc87wedt8cdtms6whu9z',
  },
];

const MainLayout: React.FC<{
  children: React.ReactNode | React.ReactNode[] | React.ReactElement | React.ReactElement[];
}> = ({ children }) => {
  const { isMobileView } = useWindowSize();
  const { theme } = useTheme();
  const [address] = useConfigReducer('address');
  const dispatch = useDispatch();
  const location = useLocation();

  const isShowHeader = useMemo(() => {
    return !IGNORE_HEADER_ROUTES.find((r) => r === location.pathname);
  }, [location.pathname]);

  const getPairs = useCallback(async () => {
    dispatch(setListToken(DATA_PAIRS));
    dispatch(setListTokenFilterInitial(DATA_PAIRS));
  }, [dispatch]);

  useEffect(() => {
    getPairs();
  }, []);

  useEffect(() => {
    dispatch(setCurrentToken(DATA_PAIRS.find((pair) => pair.route_key === location.pathname) || DATA_PAIRS[0]));
  }, [location.pathname]);

  return (
    <div>
      {isShowHeader ? (
        <>
          <Header />
          <RenderWhen isTrue={isMobileView}>
            <RenderWhen.If isTrue={!!address}>
              <div
                style={{
                  padding: '10px 15px',
                  background: theme === 'dark' ? '#151619' : '#fcfcfc',
                  marginBottom: '8px',
                }}
              >
                <ConnectWallet hasButtonConnect={false} />
                <SelectSwapTokenWrapper />
              </div>
            </RenderWhen.If>
          </RenderWhen>
          {/* <RenderWhen isTrue={isMobileView}>
            <RenderWhen.If isTrue>
              <div
                style={{
                  padding: '10px 15px',
                  background: theme === 'dark' ? '#151619' : '#fcfcfc',
                  marginBottom: '8px',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <TopRanking />
              </div>
            </RenderWhen.If>
          </RenderWhen> */}
        </>
      ) : null}

      {children}
    </div>
  );
};

export default memo(MainLayout);
