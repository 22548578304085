const ROOT_ENV = {
  REACT_APP_ATOM_ORAICHAIN_DENOM: 'ibc/A2E2EEC9057A4A1C2C0A6A4C78B0239118DF5F278830F50B4A6BDD7A66506B78',
  REACT_APP_OSMOSIS_ORAICHAIN_DENOM: 'ibc/9C4DCD21B48231D0BC2AC3D1B74A864746B37E4292694C93C617324250D002FC',
  REACT_APP_MILKY_SUB_NETWORK_DENOM: 'ibc/81ACD1F7F5380CAA3F590C58C699FBD408B8792F694888D7256EEAF564488FAB',
  INJECTIVE_ORAICHAIN_DENOM: 'ibc/49D820DFDE9F885D7081725A58202ABA2F465CAEE4AFBC683DFB79A8E013E83E',
  REACT_APP_AIRI_CONTRACT: 'orai10ldgzued6zjp0mkqwsv2mux3ml50l97c74x8sg',
  REACT_APP_ORAIX_CONTRACT: 'orai1lus0f0rhx8s03gdllx2n6vhkmf0536dv57wfge',
  REACT_APP_USDT_CONTRACT: 'orai12hzjxfh77wl572gdzct2fxv2arxcwh6gykc7qh',
  REACT_APP_USDC_CONTRACT: 'orai15un8msx3n5zf9ahlxmfeqd2kwa5wm0nrpxer304m9nd5q6qq0g6sku5pdd',
  REACT_APP_KWT_CONTRACT: 'orai1nd4r053e3kgedgld2ymen8l9yrw8xpjyaal7j5',
  REACT_APP_MILKY_CONTRACT: 'orai1gzvndtzceqwfymu2kqhta2jn6gmzxvzqwdgvjw',
  REACT_APP_SCORAI_CONTRACT: 'orai1065qe48g7aemju045aeyprflytemx7kecxkf5m7u5h5mphd0qlcs47pclp',
  REACT_APP_TRX_CONTRACT: 'orai1c7tpjenafvgjtgm9aqwm7afnke6c56hpdms8jc6md40xs3ugd0es5encn0',
  REACT_APP_ROUTER_V2_CONTRACT: 'orai1j0r67r9k8t34pnhy00x3ftuxuwg0r6r4p8p6rrc8az0ednzr8y9s3sj2sf',
  REACT_APP_CONVERTER_CONTRACT: 'orai14wy8xndhnvjmx6zl2866xqvs7fqwv2arhhrqq9',
  REACT_APP_MULTICALL_CONTRACT: 'orai1q7x644gmf7h8u8y6y8t9z9nnwl8djkmspypr6mxavsk9ual7dj0sxpmgwd',
  REACT_APP_ORDERBOOK_CONTRACT: 'orai1nt58gcu4e63v7k55phnr3gaym9tvk3q4apqzqccjuwppgjuyjy6sxk8yzp',
  REACT_APP_BASE_API_URL: 'https://server.oraidex.io',
  REACT_APP_WS_URL: 'wss://server.oraidex.io/websocket',
  REACT_APP_PERPETUAL_ENGINE_CONTRACT: 'orai1wrkchuss9wtph4mxrzqksfrulj7hsl89z0048hg8l7hcglse5rxqea2qnr',
  REACT_APP_SENTRY_ENVIRONMENT: 'production',
};

export default ROOT_ENV;
