import { Position, Side } from '@oraichain/oraimargin-contracts-sdk';
import { Asset, OrderDirection } from '@oraichain/oraidex-contracts-sdk';

import { Themes } from 'context/ThemeContext';
import { CoinGeckoPrices } from 'hooks/useCoingecko';
import { PositionTypeKeys } from 'pages/Trading/OrderManagement/constants';

export type PriceAmountFilled = {
  price: string;
  amount: string;
};

export enum KeyTypeOrderMarginTrading {
  market = 'market',
  limit = 'limit',
}

export enum FutureType {
  long = 'buy',
  short = 'sell',
}
export interface MarginTrading {
  principal: string;
  principalPercent: number;
  leverage: string;
  typeMargin: Side;
  typeOrder: KeyTypeOrderMarginTrading;
  takeProfit: string;
  takeProfitPercent: number;
  stopLoss: string;
  stopLossPercent: number;
  slippagePercent: string;
  openPositions: Array<Position>;
  positionHistory: Array<PositionHistory>;
  isRefreshUserPosition: boolean;
  isNewEventEmitted: boolean;
  entryPrice: number;
  isIgnoreStopLoss?: boolean;
  baseAssetLimit?: string;
  vammDecimal: string;
  maxLeverage: number;
  cumulativePremiumFraction: string;
}

export interface ConfigState {
  address: string;
  statusChangeAccount: boolean;
  theme: Themes;
  coingecko: CoinGeckoPrices<string>;
  cosmosAddress: { [key: string]: string };
  persistVersion: number;
}

export interface LeaderBoardState {
  showExplain: boolean;
}

export interface RecentlyTraded {
  pair: string;
  hash: string;
  height: number;
  time: string;
  orderId: number;
  status: string;
  direction: DirectionTrade;
  bidderAddr: string;
  offerAmount: number;
  askAmount: number;
  filledOfferAmount: number;
  filledAskAmount: number;
  fee?: string;
}

export interface Orderbook {
  id: number | string;
  price: string;
  ask_amount: string;
  offer_amount: string;
  side: OrderSide;
  status: number;
  time: string;
}

export type PairToken = {
  id: string | number;
  from: string;
  to: string;
  slippage: string;
  symbol: string;
  price_decimal?: number;
  price_decimal_round?: number;
  info: string;
  vamm_contract_address: string;
  route_key: string;
  start_time_for_candle?: number;
};

export interface DataPosition {
  positionId: number | string;
  blockTime: Date | number;
  symbol: string;
  side: Side;
  leverage: string | number;
  type?: PositionTypeKeys | string | number;
  size: string | number;
  price?: string | number;
  fee: string | number;
  pnl?: string | number;
  hash?: string;
  margin?: string | number;
  notional?: string | number;
  entryPrice?: string | number;
  closePrice?: string | number;
  status?: string | number;
  stopLoss?: string | number;
  takeProfit?: string | number;
  realized?: number;
}

export interface TradingState {
  listToken: PairToken[];
  listTokenFilter: PairToken[];
  currentToken: PairToken | null;
  dataRecentlyTraded: RecentlyTraded[];
  dataSellOrderbook: OrderDetailFromContract[];
  dataBuyOrderbook: OrderDetailFromContract[];
  valueChartMove: ValueChartMove | null;
  currentPrice: string;
}

export interface CandlestickMove {
  type: 'up' | 'down';
  close: number;
  high: number;
  low: number;
  open: number;
  time: string | object;
  ratio: {
    num: number;
    percent: number;
  };
}

export interface HistogramMove {
  value: number;
  color?: string;
  time: string | object;
}
export interface ValueChartMove {
  candlestick: CandlestickMove;
  histogram: HistogramMove;
}
export interface TypeDecimal {
  key: string;
  title: string;
}

export enum BuySellFilter {
  sell = 'sell',
  buy = 'buy',
  all = 'all',
}
export interface OrderDetail {
  id: number | string;
  price: string;
  ask_amount: string;
  offer_amount: string;
  side: OrderSide;
  status: number;
  time: string;
  trade_sequence: number;
  fee: string;
}

export interface OrderDetailFromContract {
  order_id: number;
  direction: OrderDirection;
  bidder_addr: string;
  offer_asset: Asset;
  ask_asset: Asset;
  filled_offer_amount: string;
  filled_ask_amount: string;
  amount?: string;
  price?: string;
  prices?: number[];
  priceKey?: string;
}

export interface PositionHistory {
  orderId: string | number;
  time: number | Date;
  direction: string;
  leverage: string | number;
  status: string | number;
  askAmount: number;
  marginAmount: number;
  offerAmount: number;
  entryPrice: string | number;
  closePrice: string | number;
  fee: string | number;
  pnl: string;
  hash: string;
  bidderAddr: string;
  stopLoss?: string | number;
  takeProfit?: string | number;
}

export interface OrderHistories {
  id: number | string;
  price: string;
  ask_amount: string;
  offer_amount: string;
  side: OrderSide;
  status: number;
  time: string;
  fee: string | null;
  trade_sequence: number;
}

export interface CandleTrades {
  time: number | string;
  open: string;
  close: string;
  high: string;
  low: string;
  volume: string;
  priceKey?: string;
  prices?: number[];
}

export interface Order {
  orders: OrderDetail[];
}
export interface OrderResponseContract {
  orders: OrderDetailFromContract[];
}

export enum OrderStatus {
  OPEN = 'Open',
  FUL_FILLED = 'Fulfilled',
  FILLING = 'Filling',
  CLOSE = 'Close',
  CANCELED = 'Cancel',
  // future
  OPEN_POSITION = 'OPEN_POSITION',
  CLOSE_POSITION = 'CLOSE_POSITION',
}

export enum PositionStatus {
  OPEN = 'Open',
  CLOSE = 'Close',
  SLCLOSE = 'SLCLOSE',
  TPCLOSE = 'TPCLOSE',
}

export enum StatusFuture {
  open_position = 'Open',
  close_position = 'Close',
  TRIGGER_TAKE_PROFIT = 'TP', // take profit
  TRIGGER_STOP_LOSS = 'SL', // stop loss.
  liquidate = 'Liquidate',
}

export enum TradeStatus {
  FULFILLED = 'Fulfilled',
  CANCEL = 'Cancel',
  OPEN = 'Open',
}

export enum OrderSide {
  SELL = 1,
  BUY = 2,
}

export enum DirectionTrade {
  SELL = 'Sell',
  BUY = 'Buy',
}
