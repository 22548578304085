import ENV from './config';

const env = {
  ...ENV,
  REACT_APP_BASE_API_URL: 'https://server-staging.oraidex.io',
  REACT_APP_WS_URL: 'wss://server-staging.oraidex.io/websocket',
  REACT_APP_IS_TESTNET: false,
  REACT_APP_SENTRY_ENVIRONMENT: '',
};

export default env;
