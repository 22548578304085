export const TAKE_PROFIT_PERCENT = [0.1, 0.5, 1.0, 3.0];
export const STOP_LOSS_PERCENT = [0, -0.25, -0.5, -0.75];

export const SPREAD = 0.0001;
export const LID_MARGIN_PERCENT = 0.9;

export const MIN_LEVERAGE = 1;

export const MIN_SLIPPAGE = 0;
export const MAX_SLIPPAGE = 50;
