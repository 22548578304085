import PRODUCT_FUTURE from './PRODUCT_FUTURE';
import STAGING_FUTURE from './STAGING_FUTURE';
import PRODUCT_ORDERBOOK from './PRODUCT_ORDERBOOK';
import STAGING_ORDERBOOK from './STAGING_ORDERBOOK';
import DEVELOPMENT_FUTURE from './DEVELOPMENT_FUTURE';

const REACT_APP_ENV = process.env.REACT_APP_ENV;
const WRAP_ENV = {
  PRODUCT_FUTURE,
  PRODUCT_ORDERBOOK,
  STAGING_ORDERBOOK,
  STAGING_FUTURE,
  DEVELOPMENT_FUTURE,
};

export const ROOT_ENV = WRAP_ENV[REACT_APP_ENV || 'PRODUCT_ORDERBOOK'];
