export const ROUTES = {
  RANKING: '/ranking',
  TRADE: '/trade/:mode',
  TERM: '/terms',
};

export const MODE = {
  // SPOT: 'spot', // TODO: Remove comment when done test
  FUTURE: 'future',
};
