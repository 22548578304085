import classNames from 'classnames';
import { FC } from 'react';
import styles from './CheckBox.module.scss';
import { Checked, Uncheck } from 'assets/icons';

const CheckBox: FC<{
  className?: string;
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onCheck: Function;
}> = ({ className, label, checked = false, onCheck, disabled }) => (
  <label className={classNames(styles.container, className, disabled ? styles.disabled : '')}>
    {label}
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        onCheck(e.target.checked);
      }}
    />
    <span className={styles.checkmark}>
      <img src={checked ? Checked : Uncheck} alt="logo" />
    </span>
  </label>
);

export default CheckBox;
