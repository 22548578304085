import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ConfigState } from './type';
import { PERSIST_VER } from 'redux/constants';

export const initialState: ConfigState = {
  address: '',
  statusChangeAccount: false,
  cosmosAddress: {},
  theme: 'dark',
  coingecko: {},
  persistVersion: PERSIST_VER,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: {
      reducer(state, action: PayloadAction<string, string, ConfigState[keyof ConfigState]>) {
        state[action.payload] = action.meta;
      },
      prepare(key: string, value: ConfigState[keyof ConfigState]) {
        return { payload: key, meta: value };
      },
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;
