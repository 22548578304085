import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { PairToken, RecentlyTraded, TradingState, OrderDetailFromContract } from './type';
import { searchTokenWithSymbol } from 'helpers/orderbook';

const initialState: TradingState = {
  listToken: [],
  listTokenFilter: [],
  currentToken: null,
  dataRecentlyTraded: [],
  dataBuyOrderbook: [],
  dataSellOrderbook: [],
  valueChartMove: null,
  currentPrice: '',
};

const tradingSlice = createSlice({
  name: 'trading',
  initialState,
  reducers: {
    searchToken: (state, action: PayloadAction<string>) => {
      state.listTokenFilter = searchTokenWithSymbol(action.payload, current(state).listToken);
    },
    setListToken: (state, action: PayloadAction<PairToken[]>) => {
      state.listToken = action.payload;
    },
    setListTokenFilterInitial: (state, action: PayloadAction<PairToken[]>) => {
      state.listTokenFilter = action.payload;
    },
    setCurrentToken: (state, action: PayloadAction<PairToken>) => {
      state.currentToken = action.payload;
    },
    addDataRecentlyTraded: (state, action: PayloadAction<RecentlyTraded[]>) => {
      state.dataRecentlyTraded = action.payload;
    },
    addSellOrderbookTraded: (state, action: PayloadAction<OrderDetailFromContract[]>) => {
      state.dataSellOrderbook = action.payload;
    },
    addBuyOrderbookTraded: (state, action: PayloadAction<OrderDetailFromContract[]>) => {
      state.dataBuyOrderbook = action.payload;
    },
    setCurrentPrice: (state, action: PayloadAction<string>) => {
      state.currentPrice = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  searchToken,
  setCurrentToken,
  setListToken,
  setListTokenFilterInitial,
  addDataRecentlyTraded,
  addSellOrderbookTraded,
  addBuyOrderbookTraded,
  setCurrentPrice,
} = tradingSlice.actions;

export const selectTokenFilter = (state: RootState): PairToken[] => state.trading.listTokenFilter;
export const selectCurrentToken = (state: RootState): PairToken => state.trading.currentToken;
export const selectDataRecentlyTraded = (state: RootState): RecentlyTraded[] => state.trading.dataRecentlyTraded;
export const selectSellDataOrderbook = (state: RootState): OrderDetailFromContract[] => state.trading.dataSellOrderbook;
export const selectBuyDataOrderbook = (state: RootState): OrderDetailFromContract[] => state.trading.dataBuyOrderbook;
export const selectCurrentPrice = (state: RootState): string => state.trading.currentPrice;
export const selectListToken = (state: RootState): PairToken[] => state.trading.listToken;

export default tradingSlice.reducer;
