import ENV from './config';

const env = {
  ...ENV,
  REACT_APP_BASE_API_URL: 'https://server.oraidex.io',
  REACT_APP_WS_URL: 'wss://server.oraidex.io/websocket',
  REACT_APP_SENTRY_ENVIRONMENT: 'production',
  REACT_APP_IS_TESTNET: false,
};

export default env;
