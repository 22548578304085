import React from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import useTheme from 'hooks/useTheme';
import { IconCloseDark, IconCloseLight } from 'assets/icons';
const cx = cn.bind(styles);

const Drawer: React.FC<{
  children: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[];
  visible: boolean;
  close: () => void;
  contentWidth?: number;
  position?: 'bottom' | 'left' | 'right';
  closeIcon?: string;
}> = ({ children, visible, close, contentWidth, position, closeIcon }) => {
  const { theme } = useTheme();
  const closeIconSrc = closeIcon || (theme === 'dark' ? IconCloseLight : IconCloseDark);
  return (
    <div className={cx('drawer', theme, visible ? 'visible' : '', position || 'right')}>
      <div className={cx('drawer-overlay')} onClick={close}></div>
      <div className={cx('drawer-content')} style={{ width: contentWidth ?? 300 }}>
        <div className={cx('drawer-close')} onClick={close}>
          <img src={closeIconSrc} alt="close" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Drawer;
