import { Table, TableHeaderProps } from 'components/SortedTable';
import useTheme from 'hooks/useTheme';
import styles from './TableData.module.scss';
import { DEFAULT_SORT_EARLY_ACCESS, DEFAULT_SORT_FIELD } from './constants';
import { LeaderBoardEarlyTrading } from './types';

export default function EarlyAccessTable({
  data,
  fixedData,
}: {
  data: LeaderBoardEarlyTrading[];
  fixedData?: LeaderBoardEarlyTrading[];
}) {
  const { theme } = useTheme();

  const headers: TableHeaderProps<LeaderBoardEarlyTrading> = {
    // rank: {
    //   name: 'Rank',
    //   width: '20%',
    //   accessor: (data) => {
    //     return (
    //       <div className={`${styles.rank}`}>
    //         <div className={styles.rankItem}>{data.rank}</div>
    //       </div>
    //     );
    //   },
    //   sortField: 'point',
    //   align: 'center',
    // },
    walletAddress: {
      name: 'Wallet address',
      width: '50%',
      accessor: (data) => (
        <div className={styles.address}>
          <div>{data.bidderAddr}</div>
        </div>
      ),
      align: 'left',
    },
    volume: {
      name: 'Block Height',
      width: '50%',
      accessor: (data) => {
        return (
          <div className={styles.data}>
            <div className={styles.firstHeight}>{data.firstHeight?.toString()}</div>
          </div>
        );
      },
      align: 'right',
    },
  };
  return (
    <div className={`${styles.tableVolume} ${styles[theme]}`}>
      <Table fixedDataSource={fixedData} data={data} headers={headers} defaultSorted={DEFAULT_SORT_FIELD} />
    </div>
  );
}
