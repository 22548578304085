import styles from './TableData.module.scss';

export const DECIMAL = 6;

export const RANK_COMPARE = 5;

export const RankStyle = {
  '1': styles.goldRank,
  '2': styles.silverRank,
  '3': styles.bronzeRank,
  '4': styles.bronzeRank,
  '5': styles.bronzeRank,
};

export const DEFAULT_SORT_FIELD = 'point';
export const DEFAULT_SORT_EARLY_ACCESS = 'firstHeight';

export const START = 1705399200; //  17h00 16/01/2024
export const END = 1707213600; // 10h00 06/02/2024
export const LIMIT_LEADERBOARD = 20;
export const MILLISECOND = 1000;

export const MIN_VALUE = 1e-4;
export const NEGATIVE_MAX_VALUE = -1e-4;
export const DECIMAL_FIXED = 4;
export const DECIMAL_FIXED_PERCENT = 2;
export const ZERO_VALUE = '≈ $0';
