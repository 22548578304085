import { END, LIMIT_LEADERBOARD, START } from 'components/LeaderBoardContent/constants';
import { Leaderboard, LeaderboardUser } from 'components/LeaderBoardContent/types';
import { BASE_API_URL } from 'config/constants';
import { parseParams } from 'libs/utils';
import { OrderbookSide } from 'pages/Trading/OrderDriven';
import axios from 'rest/request';

export type SymbolParams = {
  symbol: string;
};

export type TimeParams = {
  startTime?: number;
  endTime?: number;
};

export type LimitOffsetParams = {
  offset?: number;
  limit?: number;
};

export type GetListOrderFromPairParams = SymbolParams &
  Required<LimitOffsetParams> & {
    status: string;
    bidder?: string;
  };

export type GetTradedCandleInfoParams = SymbolParams &
  Required<TimeParams> & {
    tf: string;
  };

export type GetVolume24hByTimeParams = SymbolParams & Required<TimeParams>;

export type GetFutureCandleInfoParams = SymbolParams &
  Required<TimeParams> & {
    tf: string;
  };

export type GetListPositionWithPairIdParams = SymbolParams &
  Required<LimitOffsetParams> & {
    status?: string | number;
    bidder?: string;
    side?: OrderbookSide;
  };

export type GetPositionDetailParams = SymbolParams & {
  bidder: string;
  status?: string;
  orderId: number;
};

const withBaseApiUrl = (url: string) => BASE_API_URL + url;

// Deprecated
export const getListOrderFromPair = async (params: GetListOrderFromPairParams) => {
  try {
    return await axios.get(withBaseApiUrl('/v1/order/history'), {
      params,
      paramsSerializer: (params) => parseParams(params),
    });
  } catch (error) {
    return { data: { items: [] } };
  }
};

// Deprecated
export const getTradedCandleInfo = async (params: Readonly<GetTradedCandleInfoParams>) => {
  try {
    return await axios.get(withBaseApiUrl('/v1/candles'), { params });
  } catch (error) {
    return { data: [] };
  }
};

export const getVolume24hByTime = async (params: GetVolume24hByTimeParams) => {
  try {
    return await axios.get(withBaseApiUrl('/future/volume'), { params });
  } catch (error) {
    console.error('ERROR getVolume24hByTime: ', error);
    return { data: [] };
  }
};

export const getFutureCandleInfo = async (params: GetFutureCandleInfoParams) => {
  try {
    return await axios.get(withBaseApiUrl('/v1/future/candles'), { params });
  } catch (error) {
    return { data: [] };
  }
};
// Deprecated
export const getProductPriceInfo = async (params: { pair: string }) => {
  try {
    return await axios.get(withBaseApiUrl('/v1/current_price'), { params });
  } catch (error) {
    return { data: null };
  }
};

export const getListPositionWithPairId = async (params: GetListPositionWithPairIdParams) => {
  const paramsCovered = {};

  try {
    return await axios.get(withBaseApiUrl('/v1/positions'), {
      params: {
        ...params,
        ...paramsCovered,
      },
      paramsSerializer: (params) => parseParams(params),
    });
  } catch (error) {
    return { data: { items: [] } };
  }
};

export const getPositionDetail = async (params: GetPositionDetailParams) => {
  try {
    return await axios.get(withBaseApiUrl('/v1/position/detail'), { params });
  } catch (error) {
    return { data: { items: {} } };
  }
};

export const getLeaderBoardData = async (params?: {
  pair?: string;
  startTime?: number;
  endTime?: number;
}): Promise<Leaderboard> => {
  const start = START; //  00h00 16/01/2024
  const end = END; // 23h59 23/01/2024
  const { startTime, endTime } = params || {
    startTime: start,
    endTime: end,
  };

  try {
    const res = await axios.get(withBaseApiUrl('/leaderboard-future'), {
      params: { ...params, limit: LIMIT_LEADERBOARD },
    });

    return (
      res.data?.result || {
        volume: [],
        earlyAccess: [],
        absolutePNL: [],
        relativePNL: [],
      }
    );
  } catch (error) {
    return {
      volume: [],
      earlyAccess: [],
      absolutePNL: [],
      relativePNL: [],
    };
  }
};

export const getLeaderBoardDataByUser = async (
  address: string,
  params?: {
    pair?: string;
    startTime?: number;
    endTime?: number;
  }
): Promise<LeaderboardUser | null> => {
  try {
    const res = await axios.get(withBaseApiUrl(`/leaderboard-future/${address}`), {
      params: { ...params },
    });

    return (
      res.data || {
        volume: null,
        earlyAccess: null,
        absolutePNL: null,
        relativePNL: null,
      }
    );
  } catch (error) {
    return {
      volume: null,
      earlyAccess: null,
      absolutePNL: null,
      relativePNL: null,
    };
  }
};
