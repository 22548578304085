import {
  LogoAiriDark,
  LogoAiriLight,
  LogoAtomDark,
  LogoAtomLight,
  LogoKwtDark,
  LogoKwtLight,
  LogoMilkyDark,
  LogoMilkyLight,
  LogoOraiDark,
  LogoOraiLight,
  LogoOraixDark,
  LogoOraixLight,
  LogoOsmoDark,
  LogoOsmoLight,
  LogoScoraiDark,
  LogoScoraiLight,
  LogoUsdcDark,
  LogoUsdcLight,
  LogoUsdtDark,
  LogoUsdtLight,
  InjIcon,
  Bitcoin,
  Toncoin,
} from 'assets/icons';
import { COSMOS_DECIMALS, ORAI } from './constants';
import { ROOT_ENV } from 'env';
import { BTC_CONTRACT } from '@oraichain/oraidex-common';

export const infoTokens = [
  {
    keyAmount: 'orai',
    name: 'ORAI',
    denom: ORAI,
    decimals: COSMOS_DECIMALS,
    isNativeToken: true,
    logoDark: LogoOraiDark,
    logoLight: LogoOraiLight,
    coinGeckoId: 'oraichain-token',
  },
  {
    keyAmount: 'inj',
    name: 'INJ',
    denom: ROOT_ENV.INJECTIVE_ORAICHAIN_DENOM,
    decimals: 18,
    isNativeToken: true,
    logoDark: InjIcon,
    logoLight: InjIcon,
    coinGeckoId: 'injective',
  },
  {
    keyAmount: ROOT_ENV.REACT_APP_ATOM_ORAICHAIN_DENOM,
    name: 'ATOM',
    denom: ROOT_ENV.REACT_APP_ATOM_ORAICHAIN_DENOM,
    decimals: COSMOS_DECIMALS,
    isNativeToken: true,
    logoDark: LogoAtomDark,
    logoLight: LogoAtomLight,
    coinGeckoId: 'cosmos',
  },
  {
    keyAmount: ROOT_ENV.REACT_APP_OSMOSIS_ORAICHAIN_DENOM,
    name: 'OSMO',
    denom: ROOT_ENV.REACT_APP_OSMOSIS_ORAICHAIN_DENOM,
    decimals: COSMOS_DECIMALS,
    isNativeToken: true,
    logoDark: LogoOsmoDark,
    logoLight: LogoOsmoLight,
    coinGeckoId: 'osmosis',
  },
  {
    keyAmount: 'airi',
    name: 'AIRI',
    denom: ROOT_ENV.REACT_APP_AIRI_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoAiriDark,
    logoLight: LogoAiriLight,
    coinGeckoId: 'airight',
  },
  {
    keyAmount: 'oraix',
    name: 'ORAIX',
    denom: ROOT_ENV.REACT_APP_ORAIX_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoOraixDark,
    logoLight: LogoOraixLight,
    coinGeckoId: 'oraidex',
  },
  {
    keyAmount: 'usdt',
    name: 'USDT',
    denom: ROOT_ENV.REACT_APP_USDT_CONTRACT || 'orai12hzjxfh77wl572gdzct2fxv2arxcwh6gykc7qh',
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoUsdtDark,
    logoLight: LogoUsdtLight,
    coinGeckoId: 'tether',
  },
  {
    keyAmount: 'kwt',
    name: 'KWT',
    denom: ROOT_ENV.REACT_APP_KWT_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoKwtDark,
    logoLight: LogoKwtLight,
    coinGeckoId: 'kawaii-islands',
  },
  {
    keyAmount: 'milky',
    name: 'MILKY',
    denom: ROOT_ENV.REACT_APP_MILKY_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoMilkyDark,
    logoLight: LogoMilkyLight,
    coinGeckoId: 'milky-token',
  },
  {
    keyAmount: 'scorai',
    name: 'scORAI',
    denom: ROOT_ENV.REACT_APP_SCORAI_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoScoraiDark,
    logoLight: LogoScoraiLight,
    coinGeckoId: 'scorai',
  },
  {
    keyAmount: 'usdc',
    name: 'USDC',
    denom: ROOT_ENV.REACT_APP_USDC_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoUsdcDark,
    logoLight: LogoUsdcLight,
    coinGeckoId: 'usd-coin',
  },
  {
    keyAmount: 'btc',
    name: 'BTC',
    denom: BTC_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: Bitcoin,
    logoLight: Bitcoin,
    coinGeckoId: 'bitcoin',
  },
  {
    keyAmount: 'ton',
    name: 'TON',
    denom: 'TON_CONTRACT',
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: Toncoin,
    logoLight: Toncoin,
    coinGeckoId: 'the-open-network',
  },
];
