import { displayToast, TToastType } from 'components/Toasts';
import { GAS_ESTIMATION_ORDERBOOK_DEFAULT, ORAI } from 'config/constants';
import { infoTokens } from 'config/orderbook';
import { convertTimestampToTime, feeEstimateDefault } from 'helpers';
import { Order } from 'hooks/useOrderbook';
import { capitalizeString, roundWithDecimalPlaces, toDisplay } from 'libs/utils';
import { Asset, OrderDirection, OrderFilter } from '@oraichain/oraidex-contracts-sdk';
import { IOrderbook } from 'pages/Trading/OrderDriven';
import { OrderSideTexts, OrderTypeTexts } from 'pages/Trading/OrderManagement/constants';
import { DataOrder } from 'pages/Trading/OrderManagement/OpenOrders';
import {
  Orderbook,
  OrderSide,
  PairToken,
  OrderDetailFromContract,
  DirectionTrade,
  RecentlyTraded,
  TradeStatus,
} from 'redux/reducers/type';
import { Coin } from '@cosmjs/stargate';

export const PRICE_DECIMAL_DEFAULT = 6;
const AMOUNT_DECIMAL = 3;
const TOTAL_DECIMAL = 3;
const DECIMAL_TOKEN_DEFAULT = 6;

export const checkWalletAddress = async () => {
  const walletAddress = await window.Keplr.getKeplrAddr();
  if (!walletAddress) {
    return '';
  }

  return walletAddress;
};

export const isNativeToken = (denom: string) => {
  return infoTokens.find((token) => token.denom === denom)?.isNativeToken;
};

export const getInfoAsset = (denom: string) => {
  return isNativeToken(denom)
    ? {
        native_token: {
          denom,
        },
      }
    : {
        token: {
          contract_addr: denom,
        },
      };
};

export const generateMsgSubmitOrder = (order: Order) => {
  const [base, quote, direction] = order;
  const asset = (amount, info) => ({ amount, info });

  return {
    submit_order: {
      assets: [asset(base.amount, getInfoAsset(base.denom)), asset(quote.amount, getInfoAsset(quote.denom))],
      direction,
    },
  };
};

export const generateAssetOrder = (coin: Coin): Asset => {
  return {
    amount: coin.amount,
    info: getInfoAsset(coin.denom),
  };
};

export const generateMsgCancelOrder = (denomBase: string, denomQuote: string, orderId: string | number) => {
  return {
    cancel_order: {
      asset_infos: [getInfoAsset(denomBase), getInfoAsset(denomQuote)],
      order_id: orderId,
    },
  };
};

export const generateMsgGetOrder = ({
  denomBase,
  denomQuote,
  walletAddress,
  startAfter,
}: {
  denomBase: string;
  denomQuote: string;
  walletAddress: string;
  startAfter?: number;
}) => {
  return {
    orders: {
      asset_infos: [getInfoAsset(denomBase), getInfoAsset(denomQuote)],
      filter: {
        bidder: walletAddress,
      },
      start_after: startAfter,
    },
  };
};

export const generateMsgGetAllOrder = (
  denomBase: string,
  denomQuote: string,
  type: OrderDirection,
  startAfter?: number,
  filter?: OrderFilter
) => {
  const LIMIT = 100;

  const orders = {
    orders: {
      asset_infos: [getInfoAsset(denomBase), getInfoAsset(denomQuote)],
      direction: type,
      filter: filter ?? 'none',
      limit: LIMIT,
      order_by: 2,
    },
  };

  if (startAfter) {
    orders.orders['start_after'] = startAfter;
    return orders;
  }
  return orders;
};

export const formatDataOpenOrders = (response: OrderDetailFromContract[], currentPair: PairToken): DataOrder[] => {
  return response.map((order: OrderDetailFromContract) => {
    const direction = order.direction;
    const price = roundPriceBuySellWithDecimal(
      direction === 'buy'
        ? Number(order.offer_asset.amount) / Number(order.ask_asset.amount)
        : Number(order.ask_asset.amount) / Number(order.offer_asset.amount),
      getPriceDecimalCurrentPair(currentPair)
    ).toString();
    const filled = roundWithDecimalPlaces(
      toDisplay(direction === 'buy' ? order.filled_ask_amount : order.filled_offer_amount)
    );
    const amount = roundWithDecimalPlaces(
      toDisplay(direction === 'buy' ? order.ask_asset.amount : order.offer_asset.amount)
    );
    const total = roundWithDecimalPlaces(Number(price) * Number(amount)).toString();

    return {
      pair: currentPair.symbol,
      key: order.order_id,
      id: order.order_id,
      type: OrderTypeTexts.LIMIT,
      side: capitalizeString(direction) as OrderSideTexts,
      price,
      amount: amount + ' ' + getNameWithDenom(currentPair.from),
      filled: filled + ' ' + getNameWithDenom(currentPair.from),
      total: total + ' ' + getNameWithDenom(currentPair.to),
      trade_sequence: order.order_id,
    };
  });
};

const getPrice = (order: OrderDetailFromContract, decimal: number) => {
  const direction = order.direction;
  const price = roundPriceBuySellWithDecimal(
    direction === 'buy'
      ? Number(order.offer_asset.amount) / Number(order.ask_asset.amount)
      : Number(order.ask_asset.amount) / Number(order.offer_asset.amount),
    decimal
  ).toString();
  return price;
};

export const formatDataOrderbookFromContract = (
  response: OrderDetailFromContract[],
  currentPair: PairToken,
  typeDecimal: number
): IOrderbook[] => {
  const sellPriceHashmap = {};
  const sellResultPriceHashmap = {};
  const buyPriceHashmap = {};
  const buyResultPriceHashmap = {};

  response.forEach((order: OrderDetailFromContract) => {
    const direction = order.direction;
    const price = getPrice(order, getPriceDecimalCurrentPair(currentPair));
    const priceKey = getPrice(order, typeDecimal);

    const filled = roundWithDecimalPlaces(
      toDisplay(direction === 'buy' ? order.filled_ask_amount : order.filled_offer_amount)
    );
    const amount = roundWithDecimalPlaces(
      Number(direction === 'buy' ? order.ask_asset.amount : order.offer_asset.amount) *
        Math.pow(10, getDecimalWithDenom(currentPair.from) * -1)
    ).toString();

    const priceRemain = (
      (Number(order.offer_asset.amount) - Number(order.filled_offer_amount)) *
      Math.pow(10, getDecimalWithDenom(currentPair.from) * -1)
    ).toString();

    if (sellPriceHashmap[priceKey] === undefined) sellPriceHashmap[priceKey] = [];
    if (buyPriceHashmap[priceKey] === undefined) buyPriceHashmap[priceKey] = [];
    if (direction === 'sell') {
      sellPriceHashmap[priceKey].push({ ...order, amount: Number(amount) - Number(filled), price, priceKey });
    } else {
      buyPriceHashmap[priceKey].push({ ...order, amount: Number(priceRemain) / Number(priceKey), price, priceKey });
    }
  });

  for (const key in sellPriceHashmap) {
    if (sellPriceHashmap[key][0])
      sellResultPriceHashmap[key] = {
        ...sellPriceHashmap[key][0],
        amount: sellPriceHashmap[key].reduce((acc, curr: OrderDetailFromContract) => Number(curr.amount) + acc, 0),
        prices: sellPriceHashmap[key].map((order) => Number(order.price)),
      };
  }

  for (const key in buyPriceHashmap) {
    if (buyPriceHashmap[key][0])
      buyResultPriceHashmap[key] = {
        ...buyPriceHashmap[key][0],
        amount: buyPriceHashmap[key].reduce((acc, curr: OrderDetailFromContract) => Number(curr.amount) + acc, 0),
        prices: buyPriceHashmap[key].map((order) => Number(order.price)),
      };
  }

  const sellOrderbook = sortObjectByNumberKey(sellResultPriceHashmap, 'asc');
  const buyOrderbook = sortObjectByNumberKey(buyResultPriceHashmap, 'desc');

  return [...sellOrderbook, ...buyOrderbook].map((order: OrderDetailFromContract) => {
    const direction = order.direction;
    const total = roundWithDecimalPlaces(Number(order.price) * Number(order.amount), TOTAL_DECIMAL).toString();
    const amount = roundWithDecimalPlaces(Number(order.amount), AMOUNT_DECIMAL).toString();

    return {
      pair: currentPair.symbol,
      key: order.order_id,
      id: order.order_id,
      side: capitalizeString(direction),
      priceKey: order.priceKey,
      amount,
      filled: '0 ' + getNameWithDenom(currentPair.from),
      total,
      prices: order.prices,
    };
  });
};

export const calculateAmount = (order: RecentlyTraded): string => {
  let askAmount = order.filledAskAmount;
  let offerAmount = order.filledOfferAmount;

  if (order.status === TradeStatus.CANCEL) {
    askAmount = order.askAmount;
    offerAmount = order.offerAmount;
  }

  return order.direction === DirectionTrade.BUY ? askAmount.toString() : offerAmount.toString();
};

export const formatDataOrderHistories = (response: RecentlyTraded[], currentPair: PairToken) => {
  return response.map((order: RecentlyTraded) => {
    const price = roundPriceBuySellWithDecimal(
      calculateTradePrice(
        order.direction,
        order.status === TradeStatus.FULFILLED ? order.filledOfferAmount : order.offerAmount,
        order.status === TradeStatus.FULFILLED ? order.filledAskAmount : order.askAmount
      ),
      getPriceDecimalCurrentPair(currentPair)
    ).toString();
    const amount = roundWithDecimalPlaces(
      toDisplay(calculateAmount(order), getDecimalWithDenom(currentPair.from)),
      AMOUNT_DECIMAL
    );
    const fee = roundWithDecimalPlaces(
      toDisplay(order?.fee?.split(' ')[0] || '0', getDecimalWithDenom(currentPair.to)),
      DECIMAL_TOKEN_DEFAULT
    );
    const total = roundWithDecimalPlaces(Number(price) * Number(amount), TOTAL_DECIMAL).toString();

    return {
      pair: currentPair.symbol,
      key: order.orderId,
      status: order.status === TradeStatus.FULFILLED ? 'Complete' : 'Cancel',
      id: order.orderId,
      type: OrderTypeTexts.LIMIT,
      date: convertTimestampToTime({ timestamp: new Date(order.time).valueOf() / 1000, hasDate: true }),
      side: order.direction,
      price,
      amount: amount + ' ' + getNameWithDenom(currentPair.from),
      filled: '0 ' + getNameWithDenom(currentPair.from),
      fee: fee + ' ' + getNameWithDenom(currentPair.to),
      total: total + ' ' + getNameWithDenom(currentPair.to),
    };
  });
};

export const onSubmitWithTryCatch = async (callbackTry: Function, callbackFinally?: Function) => {
  try {
    await callbackTry();
  } catch (error) {
    let finalError = '';
    if (typeof error === 'string' || error instanceof String) {
      finalError = error as string;
    } else finalError = String(error);
    displayToast(TToastType.TX_FAILED, {
      message: finalError,
    });
  } finally {
    callbackFinally && callbackFinally();
  }
};

export const getDecimalWithDenom = (denom: string) =>
  infoTokens.find((token) => token.denom === denom)?.decimals ?? DECIMAL_TOKEN_DEFAULT;

export const getKeyAmountWithDenom = (denom: string) =>
  infoTokens.find((token) => token.denom === denom)?.keyAmount ?? '';

export const getNameWithDenom = (denom: string) => infoTokens.find((token) => token.denom === denom)?.name ?? 'usdt';

export const getLogoWithDenom = (denom: string) => {
  const dark = infoTokens.find((token) => token.denom === denom)?.logoDark;
  const light = infoTokens.find((token) => token.denom === denom)?.logoLight;

  return { dark, light };
};

export const isOraiToken = (denom: string) => denom === ORAI;

export const getCoingeckoIdWithDenom = (denom: string) =>
  infoTokens.find((token) => token.denom === denom)?.coinGeckoId ?? '';

export const getOrderSideTextWithKey = (key: OrderSide.BUY | OrderSide.SELL): OrderDirection =>
  key === OrderSide.BUY ? 'buy' : 'sell';

export const getFeeEstimateOrderbook = () =>
  feeEstimateDefault(getDecimalWithDenom(ORAI), GAS_ESTIMATION_ORDERBOOK_DEFAULT);

export const isEnoughFeeEstimate = (available: number, fees: number) => (available - fees < 0 ? false : true);

export const sortObjectByNumberKey = (obj: { [key: string]: Orderbook }, sort: 'asc' | 'desc') => {
  return Object.entries(obj)
    .sort(([k1], [k2]) => (sort === 'desc' ? Number(k2) - Number(k1) : Number(k1) - Number(k2)))
    .map(([, a]) => a);
};

export const handleRefreshWithWsResponse = (message: any, currentPair: PairToken, walletAddress?: string): boolean => {
  if (message === null || message.result === null) return false;

  const { result } = message;
  if (result && (Object.keys(result).length !== 0 || result.constructor !== Object)) {
    if (!result.events) return false;
    const events = result.events;
    const pair = events['wasm.pair'];
    if (!pair || !pair[0]) return false;

    const hasCurrentPair = pair[0].indexOf(currentPair.from) !== -1 && pair[0].indexOf(currentPair.to) !== -1;
    if (walletAddress) {
      const addressTo: Array<string> = events['wasm.to'];
      if (!addressTo || addressTo?.length === 0) return false;
      const hasCurrentAddress = addressTo.includes(walletAddress);
      return hasCurrentAddress && hasCurrentPair;
    }
    return hasCurrentPair;
  }

  return false;
};

export const searchTokenWithSymbol = (textSearch: string, listToken: PairToken[]): PairToken[] => {
  const getIndex = (text: string) => text.toLowerCase().indexOf(textSearch.toLowerCase());
  const tokenFilter = listToken.filter((item) => getIndex(item.symbol) >= 0);
  return tokenFilter;
};

export const getPriceDecimalCurrentPair = (currentPair: PairToken) => {
  return currentPair?.price_decimal || PRICE_DECIMAL_DEFAULT;
};

export const roundPriceBuySellWithDecimal = (price: number, decimal: number = 6) => {
  return Number(price.toFixed(decimal));
};

export const calculateTradePrice = (direction: DirectionTrade, offerAmount: number, askAmount: number) => {
  let price = 0;
  if (direction === DirectionTrade.BUY) {
    price = offerAmount / askAmount;
  } else {
    price = askAmount / offerAmount;
  }
  return price || 0;
};
