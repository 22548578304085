import ENV from './config';

const env = {
  ...ENV,
  REACT_APP_BASE_API_URL: 'https://futures-backend-staging.oraidex.io',
  REACT_APP_WS_URL: 'wss://futures-backend-staging.oraidex.io/websocket',
  REACT_APP_SENTRY_ENVIRONMENT: 'staging',
  REACT_APP_IS_TESTNET: false,
};

export default env;
