import { Table, TableHeaderProps } from 'components/SortedTable';
import useTheme from 'hooks/useTheme';
import styles from './TableData.module.scss';
import { LeaderBoardVolume } from './types';
import { DECIMAL, DEFAULT_SORT_FIELD, RankStyle } from './constants';
import { BigDecimal } from '@oraichain/oraidex-common';
import { formatDisplayUsdt } from 'helpers';

export default function VolumeTable({
  data,
  fixedData,
}: {
  data: LeaderBoardVolume[];
  fixedData?: LeaderBoardVolume[];
}) {
  const { theme } = useTheme();

  const headers: TableHeaderProps<LeaderBoardVolume> = {
    rank: {
      name: 'Rank',
      width: '20%',
      accessor: (data) => {
        return (
          <div className={`${styles.rank}`}>
            <div className={`${styles.rankItem} ${RankStyle[data.rank]}`}>{data.rank}</div>
          </div>
        );
      },
      sortField: 'point',
      align: 'center',
    },
    walletAddress: {
      name: 'Wallet address',
      width: '30%',
      accessor: (data) => (
        <div className={styles.address}>
          <div>{data.bidderAddr}</div>
        </div>
      ),
      align: 'left',
    },
    volume: {
      name: 'Volume',
      width: '50%',
      accessor: (data) => (
        <div className={styles.volume}>
          <div className={styles.volume}>
            {formatDisplayUsdt(new BigDecimal(data.volume).div(10 ** data.decimals || DECIMAL).toString())}
            {/* {data.volume} */}
          </div>
        </div>
      ),
      align: 'right',
    },
  };

  return (
    <div className={`${styles.tableVolume} ${styles[theme]}`}>
      <Table fixedDataSource={fixedData} data={data} headers={headers} defaultSorted={DEFAULT_SORT_FIELD} />
    </div>
  );
}
