import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames/bind';
import useWindowSize from 'hooks/useWindowSize';
import Drawer from 'components/Drawer';
import useTheme from 'hooks/useTheme';
import Button from 'components/Button';
import { ReactComponent as ConvertToken } from 'assets/icons/convert-small-token.svg';
import SelectSwapTokenUI from './SelectSwapTokenUI';
import styles from '../index.module.scss';
import { useSelector } from 'react-redux';
import { selectListToken } from 'redux/reducers/tradingSlice';
import { selectAmounts } from 'redux/reducers/tokenSlice';
import { getDecimalWithDenom, getKeyAmountWithDenom } from 'helpers/orderbook';
import { toDisplay } from 'libs/utils';
import { remapTokenByAddress } from 'config/bridgeTokens';
import { MINIMUM_SWAP } from '..';
import { TooltipIcon } from 'components/Tooltip';
import TokenBalance from 'components/TokenBalance';

const cx = cn.bind(styles);

const SelectSwapToken: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { isIpadView, isMobileHorizontalView, isMobileView } = useWindowSize();
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const { theme } = useTheme();

  useEffect(() => {
    setIsShowDrawer(isIpadView || isMobileHorizontalView || isMobileView);
  }, [isIpadView, isMobileHorizontalView, isMobileView]);

  if (isShowDrawer)
    return (
      <Drawer visible={true} contentWidth={290} position={'bottom'} close={handleClose}>
        <div className={cx('select-wrap-token-container-tablet', theme)}>
          <SelectSwapTokenUI />
        </div>
      </Drawer>
    );

  return (
    <div className={cx('select-wrap-token-container-desktop', theme)}>
      <SelectSwapTokenUI />
    </div>
  );
};

const SelectSwapTokenWrapper = () => {
  const selectTokenRef = useRef<any>(null);
  const listToken = useSelector(selectListToken);
  const amounts: AmountDetails = useSelector(selectAmounts);
  const [isShowSelectSwapToken, setIsShowSelectSwapToken] = useState(false);
  const { theme } = useTheme();

  const tokenSwapInfos = useMemo(() => {
    return listToken
      .map(token => {
        const decimals = getDecimalWithDenom(token?.to);
        const balance = amounts[getKeyAmountWithDenom(token?.to)] || '0';
        const amount = toDisplay(balance, decimals);

        const tokensHashmapByAddress = remapTokenByAddress();

        if (amount === 0) return;

        return {
          name: token?.to,
          amount,
          decimals,
          balance,
          tokenName: tokensHashmapByAddress[token?.to]?.name,
          Icon: tokensHashmapByAddress[token?.to]?.Icon
        };
      })
      .filter(token => token && MINIMUM_SWAP >= token.amount);
  }, [listToken, amounts]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (selectTokenRef.current && !selectTokenRef.current.contains(event.target)) {
        setIsShowSelectSwapToken(false);
        return;
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectTokenRef]);

  if (tokenSwapInfos.length === 0) return null;

  return (
    <TooltipIcon
      placement="bottom-end"
      arrow={false}
      theme={theme === 'light' ? 'light-border' : 'dark-border'}
      content={
        isShowSelectSwapToken
          ? null
          : tokenSwapInfos.map(token => {
              const { Icon } = token;
              return (
                <div key={token.tokenName} className={cx('description-small-token', theme)}>
                  <div>
                    <Icon width={20} height={20} />
                    <span className={cx('name')}>{token.tokenName}</span>
                  </div>
                  <TokenBalance
                    balance={{
                      amount: toDisplay(token.balance, token.decimals).toString()
                    }}
                    decimalScale={Math.min(6, token.decimals)}
                  />
                </div>
              );
            })
      }
    >
      <div className={cx('convert-small-token-container')}>
        <Button
          className={cx('convert-small-token', theme)}
          typeButton="outline"
          onClick={() => {
            setIsShowSelectSwapToken(!isShowSelectSwapToken);
          }}
        >
          <span className={cx('content')}>Convert small token</span>
          <ConvertToken />
        </Button>

        {isShowSelectSwapToken ? (
          <span ref={selectTokenRef}>
            <SelectSwapToken handleClose={() => setIsShowSelectSwapToken(false)} />
          </span>
        ) : null}
      </div>
    </TooltipIcon>
  );
};

export default memo(SelectSwapTokenWrapper);
