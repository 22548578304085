import { displayInstallWallet } from 'helpers';
import React, { useState } from 'react';
import ConnectWalletModal from './ConnectWalletModal';
import RenderWhen from 'components/RenderWhen';
import { networkInfo } from 'config/constants';
import Keplr from 'libs/keplr';

const RequireAuthButton: React.FC<any> = ({ address, setAddress, ...props }) => {
  const [openConnectWalletModal, setOpenConnectWalletModal] = useState(false);

  const onClick = () => {
    setOpenConnectWalletModal(true);
  };

  // only for mobile => so only need to check keplr
  const connectKeplr = async () => {
    if (!(await window.Keplr.getKeplr())) {
      return displayInstallWallet();
    }

    await window.Keplr.suggestChain(networkInfo.chainId);
    const address = await window.Keplr.getKeplrAddr();
    setAddress(address as string);
  };

  const disconnectKeplr = async () => {
    try {
      window.Keplr.disconnect();
      setAddress('');
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <React.Fragment>
      <span {...props} onClick={onClick}>
        {props.children}
      </span>
      <RenderWhen>
        <RenderWhen.If isTrue={!props.isMobile && openConnectWalletModal}>
          <ConnectWalletModal
            connectKeplr={connectKeplr}
            disconnectKeplr={disconnectKeplr}
            address={address}
            isOpen={openConnectWalletModal}
            close={() => {
              setOpenConnectWalletModal(false);
            }}
            open={() => {
              setOpenConnectWalletModal(true);
            }}
          />
        </RenderWhen.If>
      </RenderWhen>
    </React.Fragment>
  );
};

export default RequireAuthButton;
