import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LeaderBoardState } from './type';
import { RootState } from 'redux/store';

export const initialState: LeaderBoardState = {
  showExplain: true,
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    updateShowExplainLeaderBoard: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showExplain: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateShowExplainLeaderBoard } = leaderboardSlice.actions;

export const selectShowExplain = (state: RootState): boolean => state.persistStorage.leaderboard.showExplain;
