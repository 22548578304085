import { Table, TableHeaderProps } from 'components/SortedTable';
import useTheme from 'hooks/useTheme';
import styles from './TableData.module.scss';
import { LeaderBoardAbsolutePNL, LeaderBoardVolume } from './types';
import {
  DECIMAL,
  DECIMAL_FIXED,
  DECIMAL_FIXED_PERCENT,
  DEFAULT_SORT_FIELD,
  MIN_VALUE,
  NEGATIVE_MAX_VALUE,
  RankStyle,
  ZERO_VALUE,
} from './constants';
import { BigDecimal } from '@oraichain/oraidex-common';
import { formatDisplayNumber, formatDisplayUsdt } from 'helpers';

export default function RelativeTable({
  data,
  fixedData,
}: {
  data: LeaderBoardAbsolutePNL[];
  fixedData?: LeaderBoardAbsolutePNL[];
}) {
  const { theme } = useTheme();

  const headers: TableHeaderProps<LeaderBoardAbsolutePNL> = {
    rank: {
      name: 'Rank',
      width: '20%',
      accessor: (data) => {
        return (
          <div className={`${styles.rank}`}>
            <div className={`${styles.rankItem} ${RankStyle[data.rank]}`}>{data.rank}</div>
          </div>
        );
      },
      sortField: 'point',
      align: 'center',
    },
    walletAddress: {
      name: 'Wallet address',
      width: '30%',
      accessor: (data) => (
        <div className={styles.address}>
          <div>{data.bidderAddr}</div>
        </div>
      ),
      align: 'left',
    },
    volume: {
      name: 'PNL',
      width: '50%',
      accessor: (data) => {
        const value = new BigDecimal(data.sumPNL).div(10 ** data.decimals || DECIMAL).toNumber();
        let style = styles.zero;
        let showValue = formatDisplayUsdt(value);
        let showPercent = data.relativePNL.toFixed(DECIMAL_FIXED_PERCENT);

        if (data.relativePNL < 1 && data.relativePNL >= MIN_VALUE) {
          showPercent = data.relativePNL.toFixed(DECIMAL_FIXED);
        }

        if (data.relativePNL < MIN_VALUE && data.relativePNL > NEGATIVE_MAX_VALUE) {
          showPercent = '≈ ' + data.relativePNL.toFixed(DECIMAL_FIXED_PERCENT);
        }

        if (value < MIN_VALUE && value > NEGATIVE_MAX_VALUE) {
          showValue = ZERO_VALUE;
        } else if (value >= MIN_VALUE) {
          style = styles.positive;
          showValue = formatDisplayUsdt(value);
        } else {
          style = styles.negative;
        }

        return (
          <div className={styles.data}>
            <div className={`${styles.value} ${style}`}>{showValue}</div>
            <div>{showPercent}%</div>
          </div>
        );
      },
      align: 'right',
    },
  };

  return (
    <div className={`${styles.tableVolume} ${styles[theme]}`}>
      <Table fixedDataSource={fixedData} data={data} headers={headers} defaultSorted={DEFAULT_SORT_FIELD} />
    </div>
  );
}
