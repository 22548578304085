import { NetworkConfig } from 'types/network';
import { networkInfo } from './constants';
import { CustomChainInfo } from './chainInfos';
import { ROOT_ENV } from 'env';

export const network: CustomChainInfo & NetworkConfig = {
  ...networkInfo,
  prefix: networkInfo.bech32Config.bech32PrefixAccAddr,
  denom: 'orai',
  coinType: networkInfo.bip44.coinType,
  fee: { gasPrice: '0.00506', amount: '1518', gas: '2000000' }, // 0.000500 ORAI
  router: ROOT_ENV.REACT_APP_ROUTER_V2_CONTRACT,
  converter: ROOT_ENV.REACT_APP_CONVERTER_CONTRACT,
  multicall: ROOT_ENV.REACT_APP_MULTICALL_CONTRACT,
  explorer: 'https://scan.orai.io'
};
