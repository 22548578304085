import { CloseCircleIcon, CloseCircleLightIcon, CupIcon, CupLightIcon } from 'assets/icons';
import Drawer from 'components/Drawer';
import useTheme from 'hooks/useTheme';
import useWindowSize from 'hooks/useWindowSize';
import { FC, useState } from 'react';
import styles from './index.module.scss';
import LeaderBoardContent from 'components/LeaderBoardContent';

const LeaderBoardBtn: FC = () => {
  const { theme } = useTheme();
  const [visible, setVisible] = useState(false);
  const { isIpadView, isMobileHorizontalView, isMobileView } = useWindowSize();
  const closeIcon = theme === 'light' ? CloseCircleLightIcon : CloseCircleIcon;

  return (
    <div className={`${styles.leaderBoardBtnWrapper} ${styles[theme]}`}>
      <div className={styles.leaderBoardBtn} onClick={() => setVisible(true)}>
        <img src={theme === 'light' ? CupLightIcon : CupIcon} alt="cup-icon" />
        {!isMobileView && <span>Leaderboard</span>}
      </div>
      <Drawer
        closeIcon={closeIcon}
        visible={visible}
        contentWidth={580}
        position={isMobileView ? 'bottom' : 'left'}
        close={() => setVisible(false)}
      >
        <div className={styles.contentWrapper}>
          <LeaderBoardContent />
        </div>
      </Drawer>
    </div>
  );
};

export default LeaderBoardBtn;
