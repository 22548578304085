import { RANK_COMPARE } from 'components/LeaderBoardContent/constants';
import {
  LeaderBoardAbsolutePNL,
  LeaderBoardEarlyTrading,
  LeaderBoardRelativePNL,
  LeaderBoardVolume,
} from 'components/LeaderBoardContent/types';
import useConfigReducer from 'hooks/useConfigReducer';
import { reduceString } from 'libs/utils';
import { useEffect, useState } from 'react';
import { getLeaderBoardData, getLeaderBoardDataByUser } from 'services/api';

const formatLeaderBoardData = (
  dataList: LeaderBoardVolume[] | LeaderBoardAbsolutePNL[] | LeaderBoardRelativePNL[] | LeaderBoardEarlyTrading[],
  myAddress: string,
  dataUser: LeaderBoardVolume | LeaderBoardAbsolutePNL | LeaderBoardRelativePNL | LeaderBoardEarlyTrading
) => {
  const fmtDataUser = dataUser
    ? {
        ...dataUser,
        point: dataUser?.rank,
        originBidderAddr: dataUser?.bidderAddr,
        bidderAddr: reduceString(dataUser?.bidderAddr || '', 8, 7),
      }
    : null;

  const formatData = dataList.map((e, index) => {
    const rank = index + 1;
    let point = dataList.length - rank;

    e.point = e.volume;
    if (e.bidderAddr === myAddress && rank > 5) {
      point = dataList.length + 1;
    }

    return {
      ...e,
      rank,
      point,
      isHighlight: e.bidderAddr === myAddress,
      originBidderAddr: e.bidderAddr,
      bidderAddr: reduceString(e.bidderAddr, 8, 7),
    };
  });

  const data = formatData.filter((v) => !(v.originBidderAddr === myAddress && v.rank > RANK_COMPARE));
  let fixedData = formatData.filter((v) => v.originBidderAddr === myAddress && v.rank > RANK_COMPARE);
  if (fixedData?.length <= 0 && fmtDataUser && fmtDataUser.rank > RANK_COMPARE) {
    fixedData = fmtDataUser ? [fmtDataUser] : [];
  }

  return { data, fixedData };
};

export const useLeaderBoardData = (isStarted: boolean, isEnd: boolean) => {
  const [accountAddress] = useConfigReducer('address');
  const [volumeData, setVolumeData] = useState([]);
  const [fixedVolumeData, setFixedVolumeData] = useState([]);

  const [earlyAccessData, setEarlyAccessData] = useState([]);
  const [fixedEarlyData, setFixedEarlyData] = useState([]);

  const [absolutePNLData, setAbsolutePNLData] = useState([]);
  const [fixedAbsoluteData, setFixedAbsoluteData] = useState([]);

  const [relativePNLData, setRelativePNLData] = useState([]);
  const [fixedRelativeData, setFixedRelativeData] = useState([]);

  useEffect(() => {
    (async () => {
      const { volume = [], earlyAccess = [], absolutePNL = [], relativePNL = [] } = await getLeaderBoardData();
      const {
        volume: volumeUser = null,
        earlyAccess: earlyAccessUser = null,
        absolutePNL: absolutePNLUser = null,
        relativePNL: relativePNLUser = null,
      } = await getLeaderBoardDataByUser(accountAddress);

      const { data: volData, fixedData: fixedVolData } = formatLeaderBoardData(volume, accountAddress, volumeUser);

      setVolumeData(volData);
      setFixedVolumeData(fixedVolData);

      const { data: earlyData, fixedData: fixedEarlyData } = formatLeaderBoardData(
        earlyAccess,
        accountAddress,
        earlyAccessUser
      );
      setEarlyAccessData(earlyData);
      setFixedEarlyData(fixedEarlyData);

      const { data: absoluteData, fixedData: fixedAbsoluteDatas } = formatLeaderBoardData(
        absolutePNL,
        accountAddress,
        absolutePNLUser
      );
      setAbsolutePNLData(absoluteData);
      setFixedAbsoluteData(fixedAbsoluteDatas);

      const { data: relativeData, fixedData: fixedRelativeDatas } = formatLeaderBoardData(
        relativePNL,
        accountAddress,
        relativePNLUser
      );
      setRelativePNLData(relativeData);
      setFixedRelativeData(fixedRelativeDatas);
    })();
  }, [isStarted, isEnd]);

  return {
    volumeData,
    fixedVolumeData,

    earlyAccessData,
    fixedEarlyData,

    absolutePNLData,
    fixedAbsoluteData,

    relativePNLData,
    fixedRelativeData,
  };
};
