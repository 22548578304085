import { TypeDecimal } from './reducers/type';

// change version when update, add state redux-persist storage
export const PERSIST_VER = 1.1;

export const PERSIST_CONFIG_KEY: string = 'root';
export const ORDERBOOK_LEVELS: number = 25;

export const DECIMALS: TypeDecimal[] = [
  {
    key: '1',
    title: '1 decimal',
  },
  {
    key: '2',
    title: '2 decimal',
  },
  {
    key: '3',
    title: '3 decimal',
  },
  {
    key: '4',
    title: '4 decimal',
  },
  {
    key: '5',
    title: '5 decimal',
  },
  {
    key: '6',
    title: '6 decimal',
  },
];
