import { Side } from '@oraichain/oraimargin-contracts-sdk';
import { OrderStatus } from 'redux/reducers/type';

export enum PositionTypeKeys {
  TPCLOSE = 'TPCLOSE',
  SLCLOSE = 'SLCLOSE',
  CLOSE = 'CLOSE',
  OPEN = 'OPEN'
}

export const PositionDirectionKeys = {
  remove_from_amm: 'SHORT',
  add_to_amm: 'LONG'
};

export const PositionContractSideKeys = {
  sell: 'SHORT',
  buy: 'LONG',
  Buy: 'LONG',
  Sell: 'SHORT'
};

export const PositionSideKeys = {
  2: 'SHORT',
  1: 'LONG'
};

export const PositionStatusKeys = {
  6: 'CLOSE',
  5: 'OPEN'
};

export enum PositionTypeNumberKeys {
  TPCLOSE = 8,
  SLCLOSE = 7,
  CLOSE = 6,
  OPEN = 5
}

export enum OrderTypeKeys {
  LIMIT = '1',
  STOP_LIMIT = '2'
}

export enum OrderTypeTexts {
  LIMIT = 'Limit',
  STOP_LIMIT = 'Stop limit'
}

export enum OrderSideKeys {
  ALL = '1',
  BUY = '2',
  SELL = '3'
}

export enum OrderSideTexts {
  ALL = 'All',
  BUY = 'Buy',
  SELL = 'Sell'
}

export enum PositionTypeTexts {
  Long = 'LONG',
  Short = 'SHORT'
}

export const ORDER_TYPES: { key: OrderTypeKeys; title: OrderTypeTexts }[] = [
  {
    key: OrderTypeKeys.LIMIT,
    title: OrderTypeTexts.LIMIT
  },
  {
    key: OrderTypeKeys.STOP_LIMIT,
    title: OrderTypeTexts.STOP_LIMIT
  }
];

export const POSITION_TYPES: { key: PositionTypeNumberKeys; title: PositionTypeKeys }[] = [
  {
    key: PositionTypeNumberKeys.OPEN,
    title: PositionTypeKeys.OPEN
  },
  {
    key: PositionTypeNumberKeys.CLOSE,
    title: PositionTypeKeys.CLOSE
  },
  {
    key: PositionTypeNumberKeys.TPCLOSE,
    title: PositionTypeKeys.TPCLOSE
  },
  {
    key: PositionTypeNumberKeys.SLCLOSE,
    title: PositionTypeKeys.SLCLOSE
  }
];

export const ORDER_STATUS: { key: string; title: string }[] = [
  {
    key: `${OrderStatus.FUL_FILLED},${OrderStatus.CANCELED}`,
    title: 'All'
  },
  {
    key: `${OrderStatus.FUL_FILLED}`,
    title: 'Complete'
  },
  {
    key: `${OrderStatus.CANCELED}`,
    title: 'Cancel'
  },
  {
    key: `${OrderStatus.OPEN_POSITION}`,
    title: 'Open position'
  },
  {
    key: `${OrderStatus.CLOSE_POSITION}`,
    title: 'Close position'
  }
];

export const POSITION_SIDE_TYPES: { key: Side; title: string }[] = [
  {
    key: 'buy',
    title: 'Long'
  },
  {
    key: 'sell',
    title: 'Short'
  }
];

export const SIDE_TYPES: { key: OrderSideKeys; title: OrderSideTexts }[] = [
  {
    key: OrderSideKeys.ALL,
    title: OrderSideTexts.ALL
  },
  {
    key: OrderSideKeys.BUY,
    title: OrderSideTexts.BUY
  },
  {
    key: OrderSideKeys.SELL,
    title: OrderSideTexts.SELL
  }
];

export const ORDER_STATUS_MAPS = {
  2: ORDER_STATUS[1].title,
  10: ORDER_STATUS[2].title
};

export const ORDER_STATUS_CODE_MAPS = {
  [OrderStatus.OPEN]: 1,
  [OrderStatus.FUL_FILLED]: 2,
  [OrderStatus.FILLING]: 3,
  [OrderStatus.CLOSE]: 4,
  [OrderStatus.CANCELED]: 10,
  [OrderStatus.OPEN_POSITION]: 5,
  [OrderStatus.CLOSE_POSITION]: 6
};
