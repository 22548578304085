import {
  CustomChainInfo,
  CoinGeckoId,
  CoinIcon,
  NetworkChainId,
  NetworkName,
} from './chainInfos';
import { networkInfo } from './constants';


export type TokenItemType = {
  name: string;
  org: NetworkName;
  denom: string;
  prefix?: string;
  contractAddress?: string;
  Icon: CoinIcon;
  chainId: NetworkChainId;
  coinType?: number;
  rpc: string;
  decimals: number;
  maxGas?: number;
  coinGeckoId: CoinGeckoId;
  factoryV2?: boolean;
  evmDenoms?: string[];
  bridgeNetworkIdentifier?: NetworkChainId;
  bridgeTo?: NetworkChainId[];
  cosmosBased?: Boolean;
  minAmountSwap?: number;
};

export const getTokensFromNetwork = (network: CustomChainInfo): TokenItemType[] => {
  return network.currencies.map(currency => ({
    name: currency.coinDenom,
    org: network.chainName,
    coinType: network.bip44.coinType,
    contractAddress: currency.contractAddress,
    prefix: currency?.prefixToken ?? network.bech32Config?.bech32PrefixAccAddr,
    coinGeckoId: currency.coinGeckoId,
    denom: currency.coinMinimalDenom,
    decimals: currency.coinDecimals,
    chainId: network.chainId,
    rpc: network.rpc,
    lcd: network.rest,
    Icon: currency.Icon
  }));
};

export const oraichainTokens: TokenItemType[] = getTokensFromNetwork(networkInfo);

export const tokenMap = Object.fromEntries(oraichainTokens.map(c => [c.denom, c]));

export const remapTokenByAddress = () => {
  const tokensHashmapByAddress = {};
  for (const key in tokenMap) {
    if (Object.prototype.hasOwnProperty.call(tokenMap, key)) {
      const element = tokenMap[key];
      tokensHashmapByAddress[element.contractAddress || key] = element;
    }
  }

  return tokensHashmapByAddress;
};
