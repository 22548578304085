import { ChainIdEnum, CustomChainInfo, chainInfosWithIcon } from '@oraichain/oraidex-common';
import { WalletType as WalletCosmosType } from '@oraichain/oraidex-common/build/constant';
import { ReactComponent as KeplrIcon } from 'assets/icons/keplr-icon.svg';
import { ReactComponent as MetamaskIcon } from 'assets/icons/metamask-icon.svg';
import { ReactComponent as OwalletIcon } from 'assets/icons/owallet-icon.svg';

export enum WALLET_TYPES {
  METAMASK = 'METAMASK',
  METAMASK_LEAP_SNAP = 'METAMASK_LEAP_SNAP',
  KEPLR = 'KEPLR',
  OWALLET = 'OWALLET',
}

export type NetworkType = 'cosmos' | 'evm' | 'tron';
export type WalletNetwork = {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  name: string;
  isActive: boolean;
  registryName?: CosmosWalletType;
  suffixName?: string;
};

interface NetworkItem extends CustomChainInfo {
  address: string;
}

export interface WalletItem {
  id: number;
  name: string;
  code: WALLET_TYPES;
  icon: string;
  totalUsd: number;
  isOpen: boolean;
  isConnect: boolean;
  networks: NetworkItem[];
  address?: string;
}

export const cosmosWallets: WalletNetwork[] = [
  {
    icon: OwalletIcon,
    name: 'Owallet',
    registryName: 'owallet',
    isActive: true,
  },
  {
    icon: MetamaskIcon,
    name: 'Metamask (Oraichain)',
    registryName: 'eip191',
    isActive: true,
  },
  {
    icon: KeplrIcon,
    name: 'Keplr',
    registryName: 'keplr',
    isActive: true,
  },
];

export type CosmosWalletType = WalletCosmosType | 'eip191';

export enum WALLET_ENUM {
  KEPLR = 'keplr',
  OWALLET = 'owallet',
  LEAP_SNAP = 'leapSnap',
  EIP191 = 'eip191',
}

export type WalletProvider = {
  networkType: NetworkType;
  networks: any[];
  wallets: WalletNetwork[];
};

export const cosmosNetworksWithIcon = chainInfosWithIcon.filter(
  (c) => c.networkType === 'cosmos' && c.chainId !== ChainIdEnum.OraiBridge
);

export const cosmosWalletProvider: WalletProvider = {
  networkType: 'cosmos',
  networks: cosmosNetworksWithIcon,
  wallets: cosmosWallets,
};
