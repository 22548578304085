import { StarIcon } from 'assets/icons';
import CountDownTime from 'components/CountDownTime';
import { formatLeaderboardDate } from 'helpers';
import { useLeaderBoardData } from 'hooks/useLeaderBoardData';
import useTheme from 'hooks/useTheme';
import { memo, useState } from 'react';
import AbsoluteTable from './AbsoluteTable';
import EarlyAccessTable from './EarlyAccessTable';
import RelativeTable from './RelativeTable';
import VolumeTable from './VolumeTable';
import { END, MILLISECOND, START } from './constants';
import styles from './index.module.scss';

import { ReactComponent as RegistrationIcon } from 'assets/icons/registration.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowExplain, updateShowExplainLeaderBoard } from 'redux/reducers/leaderboardSlice';

export enum TabType {
  Volume = 'Volume',
  EarlyAccess = 'Early Access',
  AbsolutePNL = 'Absolute PNL',
  RelativePNL = 'Relative PNL',
}

const LeaderBoardContent = () => {
  const { theme } = useTheme();
  const [tab, setTab] = useState(TabType.Volume);
  const [isStart, setIsStart] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const dispatch = useDispatch();
  const showExplain = useSelector(selectShowExplain);

  const {
    volumeData,
    fixedVolumeData,

    earlyAccessData,
    fixedEarlyData,

    absolutePNLData,
    fixedAbsoluteData,

    relativePNLData,
    fixedRelativeData,
  } = useLeaderBoardData(isStart, isEnd);

  const generateCompetitionTab = () => {
    switch (tab) {
      case TabType.Volume:
        return <VolumeTable data={volumeData} fixedData={fixedVolumeData} />;
      case TabType.EarlyAccess:
        return <EarlyAccessTable data={earlyAccessData} fixedData={fixedEarlyData} />;
      case TabType.AbsolutePNL:
        return <AbsoluteTable data={absolutePNLData} fixedData={fixedAbsoluteData} />;
      case TabType.RelativePNL:
        return <RelativeTable data={relativePNLData} fixedData={fixedRelativeData} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={`${styles.leaderboardContent} ${styles[theme]}`}>
      <div className={styles.headerTitle}>
        <div className={styles.headerLeft}>
          {/* <div className={styles.time}>
            {formatLeaderboardDate(START * MILLISECOND)} - {formatLeaderboardDate(END * MILLISECOND)}
          </div> */}
          <div className={styles.title}>
            <span>
              Futures Trading Leaderboard
              {/* <br />
              Leaderboard */}
            </span>
            <img src={StarIcon} alt="star icon" />
          </div>
        </div>

        <div className={styles.headerRight}>
          {/* <CountDownTime onStart={() => setIsStart(true)} onEnd={() => setIsEnd(true)} /> */}
        </div>
      </div>

      {/* explain section */}
      {/* <div className={styles.explainWrapper}>
        <span
          className={`${styles.required} ${showExplain ? styles.hidden : ''}`}
          onClick={() => {
            dispatch(updateShowExplainLeaderBoard(true));
          }}
        >
          Requirements for eligible participants
        </span>
        <div className={`${styles.explain} ${!showExplain ? styles.hidden : ''}`}>
          <div className={styles.title}>
            To be eligible to the competition, you must meet all the simple requirements below:
          </div>
          <div className={styles.taskList}>
            <div className={styles.task}>
              <div className={styles.index}>1</div>
              <div className={styles.contentText}>
                Retweet this&nbsp;
                <a
                  target="_blank"
                  href="https://twitter.com/oraidex/status/1747199513281138733"
                  rel="noreferrer"
                  className={styles.highlight}
                >
                  <TwitterIcon className={styles.xIcon} />
                  &nbsp;Twitter post
                </a>
                &nbsp;and tag&nbsp;
                <a target="_blank" href="https://twitter.com/oraidex" rel="noreferrer" className={styles.highlight}>
                  @oraidex
                </a>
                &nbsp;on Twitter
              </div>
            </div>
            <div className={styles.task}>
              <div className={styles.index}>2</div>
              <div className={styles.contentText}>
                Submit the tweet link through this&nbsp;
                <a
                  href="https://forms.gle/TEt8rHA1qqAinjeB9"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.highlight}
                >
                  <RegistrationIcon className={styles.regIcon} />
                  &nbsp;Registration Form
                </a>
              </div>
            </div>
            <div className={styles.task}>
              <div className={styles.index}>3</div>
              <div>Accumulate a minimum trading volume of $1,000.</div>
            </div>
          </div>
          <span
            className={styles.showOption}
            onClick={() => {
              dispatch(updateShowExplainLeaderBoard(false));
            }}
          >
            Hide
          </span>
        </div>
      </div> */}

      {/* tab */}
      <div className={styles.tab}>
        {[TabType.Volume, TabType.AbsolutePNL, TabType.RelativePNL, TabType.EarlyAccess].map((item) => {
          return (
            <div
              onClick={() => setTab(item)}
              className={`${styles.item} ${tab === item ? styles.active : ''}`}
              key={item}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className={styles.table}>{generateCompetitionTab()}</div>
    </div>
  );
};

export default memo(LeaderBoardContent);
