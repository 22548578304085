import React from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import { ReactComponent as IconLoading } from 'assets/icons/loading-button.svg';
import RenderWhen from 'components/RenderWhen';
const cx = cn.bind(styles);

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode | React.ReactNode[] | React.ReactElement | React.ReactElement[];
  typeButton?: 'outline' | 'default';
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ disabled, children, typeButton, className, loading, ...props }) => {
  return (
    <button
      disabled={loading || disabled}
      className={cx(
        'button',
        disabled ? 'disabled' : '',
        typeButton ?? 'default',
        className ?? '',
        loading ? 'loading' : ''
      )}
      {...props}
    >
      <RenderWhen>
        <RenderWhen.If isTrue={loading}>
          <IconLoading />
        </RenderWhen.If>
      </RenderWhen>
      {children}
    </button>
  );
};

export default Button;
