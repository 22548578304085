import { LogoDark, LogoLight } from 'assets/images';
import cn from 'classnames/bind';
import useTheme from 'hooks/useTheme';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import ConnectWallet from './ConnectWallet';
import LightDarkMode from './LightDarkMode';
import styles from './index.module.scss';
// import TopRanking from './TopRanking';
import useWindowSize from 'hooks/useWindowSize';
import LeaderBoardBtn from './LeaderBoardBtn';
import SelectSwapTokenWrapper from './SelectSwapToken';

const cx = cn.bind(styles);

export const MINIMUM_SWAP = 0.01;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { isMobileView } = useWindowSize();

  return (
    <div className={cx('header', theme)}>
      <div onClick={() => navigate('/')} className={cx('header-logo')}>
        <img src={theme === 'light' ? LogoDark : LogoLight} alt="logo" />
      </div>
      <div className={cx('header-right')}>
        <LeaderBoardBtn />
        <div className={cx('header-action', isMobileView ? 'mobile' : '')}>
          <div className={cx('header-connect')}>
            <ConnectWallet />
          </div>

          <SelectSwapTokenWrapper />

          {/* <div className={cx('header-for-user')}> */}
          {/* <div className={cx('header-ranking')}> */}
          {/* <TopRanking /> */}
          {/* </div> */}
          {/* </div> */}
        </div>
        <div className={cx('header-theme')}>
          <LightDarkMode />
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
