import React, { memo } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import Button from 'components/Button';
import useConfigReducer from 'hooks/useConfigReducer';
import RequireAuthButton from 'components/ConnectWallet/RequireAuthButton';
import TokenBalance from 'components/TokenBalance';
import { useSelector } from 'react-redux';
import { selectAmounts } from 'redux/reducers/tokenSlice';
import useTheme from 'hooks/useTheme';
import useWindowSize from 'hooks/useWindowSize';
import { selectCurrentToken } from 'redux/reducers/tradingSlice';
import RenderWhen from 'components/RenderWhen';
import { getCoinDetail } from './helpers';

const cx = cn.bind(styles);

const TokenInfo: React.FC<{
  name: string;
  balance: string;
  decimals: number;
  logo: {
    dark: any;
    light: any;
  };
}> = ({ name, balance, decimals, logo }) => {
  const { theme } = useTheme();
  return (
    <div className={cx('token-info')}>
      <RenderWhen>
        <RenderWhen.If isTrue={theme === 'dark'}>
          <img src={logo.light} alt="logo" className={cx('token-avatar')} />
        </RenderWhen.If>
        <RenderWhen.If isTrue={theme !== 'dark'}>
          <img src={logo.dark} alt="logo" className={cx('token-avatar')} />
        </RenderWhen.If>
      </RenderWhen>
      <div className={cx('token-info-balance')}>
        <span className={cx('token-address')}>{name}</span>
        {
          <TokenBalance
            balance={{
              amount: balance,
              decimals,
              denom: name,
            }}
            className={cx('token-balance')}
            decimalScale={6}
          />
        }
      </div>
    </div>
  );
};

const ConnectWallet: React.FC<{
  hasButtonConnect?: boolean;
}> = ({ hasButtonConnect = true }) => {
  const { theme } = useTheme();
  const { isMobileView } = useWindowSize();
  const [address, setAddress] = useConfigReducer('address');
  const amounts: AmountDetails = useSelector(selectAmounts);
  const currentPair = useSelector(selectCurrentToken);

  const quoteCoin = getCoinDetail(currentPair?.to, amounts);

  return (
    <div className={cx('connect-wallet', theme, isMobileView ? 'mobile' : '')}>
      <RenderWhen>
        <RenderWhen.If isTrue={!!address && !!quoteCoin.name}>
          <RequireAuthButton address={address} setAddress={setAddress} isMobile={isMobileView}>
            <div className={cx('token-box')}>
              <TokenInfo
                name={quoteCoin.name}
                balance={quoteCoin.balance}
                decimals={quoteCoin.decimals}
                logo={quoteCoin.logo}
              />
            </div>
          </RequireAuthButton>
        </RenderWhen.If>

        <RenderWhen.If isTrue={!address && hasButtonConnect}>
          <RequireAuthButton address={address} setAddress={setAddress} isMobile={isMobileView}>
            <Button className={cx('btn-connect')} typeButton="outline">
              <span style={{ position: 'relative', zIndex: '1' }}>Connect Wallet</span>
            </Button>
          </RequireAuthButton>
        </RenderWhen.If>
      </RenderWhen>
    </div>
  );
};

export default memo(ConnectWallet);
