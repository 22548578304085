import { Table, TableHeaderProps } from 'components/SortedTable';
import useTheme from 'hooks/useTheme';
import styles from './TableData.module.scss';
import { LeaderBoardAbsolutePNL, LeaderBoardVolume } from './types';
import { DECIMAL, DEFAULT_SORT_FIELD, RankStyle } from './constants';
import { BigDecimal } from '@oraichain/oraidex-common';
import { formatDisplayUsdt } from 'helpers';

export default function AbsoluteTable({
  data,
  fixedData,
}: {
  data: LeaderBoardAbsolutePNL[];
  fixedData?: LeaderBoardAbsolutePNL[];
}) {
  const { theme } = useTheme();

  const headers: TableHeaderProps<LeaderBoardAbsolutePNL> = {
    rank: {
      name: 'Rank',
      width: '20%',
      accessor: (data) => {
        return (
          <div className={`${styles.rank}`}>
            <div className={`${styles.rankItem} ${RankStyle[data.rank]}`}>{data.rank}</div>
          </div>
        );
      },
      sortField: 'point',
      align: 'center',
    },
    walletAddress: {
      name: 'Wallet address',
      width: '30%',
      accessor: (data) => (
        <div className={styles.address}>
          <div>{data.bidderAddr}</div>
        </div>
      ),
      align: 'left',
    },
    volume: {
      name: 'PNL',
      width: '50%',
      accessor: (data) => (
        <div className={styles.pnl}>
          <div className={styles.sumPNL}>
            {formatDisplayUsdt(new BigDecimal(data.sumPNL).div(10 ** data.decimals || DECIMAL).toString())}
          </div>
          {/* <div>{data.relativePNL.toFixed(2)}%</div> */}
        </div>
      ),
      align: 'right',
    },
  };

  return (
    <div className={`${styles.tableVolume} ${styles[theme]}`}>
      <Table fixedDataSource={fixedData} data={data} headers={headers} defaultSorted={DEFAULT_SORT_FIELD} />
    </div>
  );
}
