import { fromBech32, toBech32 } from '@cosmjs/encoding';
import { TokenItemType } from 'config/bridgeTokens';
import { HIGH_GAS_PRICE, MULTIPLIER, TIMER } from 'config/constants';
import { network } from 'config/networks';
import React from 'react';

import { BigDecimal, ChainIdEnum, COSMOS_CHAIN_ID_COMMON, validateNumber } from '@oraichain/oraidex-common';
import { CosmosWalletType, WALLET_ENUM } from 'components/ConnectWallet/const';
import { displayToast, TToastType } from 'components/Toasts';
import { chainInfos } from 'config/chainInfos';
import { MetamaskOfflineSigner } from 'libs/eip191';
import Keplr from 'libs/keplr';
import { isMobile } from 'libs/utils';

export type DecimalLike = string | number | bigint | BigDecimal;
export type NetworkType = {
  title: string;
  chainId: string | number;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  networkType: string;
};

export const cosmosNetworks = chainInfos.filter(
  (c) => c.networkType === 'cosmos' && c.chainId !== ChainIdEnum.OraiBridge
);

export const getNetworkGasPrice = async (): Promise<number> => {
  try {
    const chainInfosWithoutEndpoints = await window.Keplr?.getChainInfosWithoutEndpoints();
    const findToken = chainInfosWithoutEndpoints.find((e) => e.chainId == network.chainId);
    if (findToken) {
      return findToken.feeCurrencies[0].gasPriceStep.average;
    }
  } catch {}
  return 0;
};

//hardcode fee
export const feeEstimate = (tokenInfo: TokenItemType, gasDefault: number) => {
  if (!tokenInfo) return 0;
  return (gasDefault * MULTIPLIER * HIGH_GAS_PRICE) / 10 ** tokenInfo?.decimals;
};

export const feeEstimateDefault = (tokenDecimal: number, gasDefault: number) => {
  return (gasDefault * MULTIPLIER * HIGH_GAS_PRICE) / 10 ** tokenDecimal;
};

export const handleCheckWallet = async () => {
  // @ts-ignore
  const isCheckOwallet = window.owallet?.isOwallet;
  const version = window?.keplr?.version;
  const isCheckKeplr = !!version && keplrCheck('keplr');
  const isMetamask = window?.ethereum?.isMetaMask;

  if (!isMobile() && !isCheckOwallet && !isCheckKeplr && !isMetamask) {
    displayInstallWallet();
    return false;
  }

  return true;
};

/**
 * Returns the number rounded to the nearest interval.
 * Example:
 *
 *   roundToNearest(1000.5, 1); // 1000
 *   roundToNearest(1000.5, 0.5);  // 1000.5
 *
 * @param {number} value    The number to round
 * @param {number} interval The numeric interval to round to
 * @return {number}
 */
export const roundToNearest = (value: number, interval: number) => {
  return Math.floor(value / interval) * interval;
};

/**
 * Groups price levels by their price
 * Example:
 *
 *  groupByPrice([ [1000, 100], [1000, 200], [993, 20] ]) // [ [ 1000, 300 ], [ 993, 20 ] ]
 *
 * @param levels
 */
export const groupByPrice = (levels: number[][]): number[][] => {
  return levels.reduce((arr: number[][], currentLevel) => {
    if (currentLevel[1]) {
      const prevLevel = arr[arr.length - 1];
      if (prevLevel && prevLevel[0] === currentLevel[0]) {
        prevLevel[1] += currentLevel[1];
      } else {
        arr.push(currentLevel);
      }
    }
    return arr;
  }, []);
};

/**
 * Group price levels by given ticket size. Uses groupByPrice() and roundToNearest()
 * Example:
 *
 * groupByTicketSize([ [1000.5, 100], [1000, 200], [993, 20] ], 1) // [[1000, 300], [993, 20]]
 *
 * @param levels
 * @param ticketSize
 */
export const groupByTicketSize = (levels: number[][], ticketSize: number): number[][] => {
  return groupByPrice(levels.map((level) => [roundToNearest(level[0], ticketSize), level[1]]));
};

export const formatNumber = (arg: number): string => {
  return new Intl.NumberFormat('en-US').format(arg);
};

export const formatPrice = (arg: number, typeDecimal: number = 2): string => {
  return arg.toLocaleString('en', {
    useGrouping: true,
    minimumFractionDigits: typeDecimal,
  });
};

export const displayInstallWallet = (altWallet = 'Keplr or Metamask') => {
  displayToast(
    TToastType.TX_INFO,
    {
      message: `You need to install OWallet or ${altWallet} to continue.`,
      customLink: 'https://chrome.google.com/webstore/detail/owallet/hhejbopdnpbjgomhpmegemnjogflenga',
      textLink: 'View on store',
    },
    {
      toastId: 'install_keplr',
    }
  );
};

export const handleCheckAddress = async (): Promise<string> => {
  const oraiAddress = await window.Keplr.getKeplrAddr();
  if (!oraiAddress) {
    throw new Error('Please login keplr!');
  }
  return oraiAddress;
};

export const isTimestampMsValid = (timestampMs: number | string) => {
  timestampMs = Number(timestampMs);
  const minTimestampMs = new Date(`${TIMER.MIN_SYSTEM_YEAR}`).getTime();

  // getTime() method of Date object returns the stored time value
  // in milliseconds since midnight, January 1, 1970 UTC.
  const maxTimestampMs = new Date('' + (new Date().getUTCFullYear() + 100)).getTime();

  return timestampMs > minTimestampMs && timestampMs < maxTimestampMs;
};

export const convertTimestampToTime = ({
  timestamp,
  symbol,
  isUTC,
  hasDate,
}: {
  timestamp: number;
  symbol?: string;
  isUTC?: boolean;
  hasDate?: boolean;
}): string => {
  const opts: any = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h24',
  };

  if (isUTC) opts.timeZone = 'UTC';
  if (hasDate)
    Object.assign(opts, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

  const isMsTime = isTimestampMsValid(timestamp);
  const timeInMs = isMsTime ? timestamp : Number(timestamp) * TIMER.MILLISECOND;

  const obj = new Intl.DateTimeFormat('en-US', opts).formatToJson(timeInMs);

  // check case 24h
  if (obj.hour === '24') {
    obj.hour = '00';
  }

  let ret = obj.hour + ':' + obj.minute + ':' + obj.second;
  if (hasDate && !isUTC) {
    const sep = symbol ?? '/';
    ret = obj.year + sep + obj.month + sep + obj.day + ' ' + ret;
  }
  return ret;
};

export const toDecimals = (num: number | string, decimals: number | string = 6, fixed: number = 0): number => {
  return Number((Number(num) * 10 ** Number(decimals)).toFixed(fixed));
};

export const getUTCTimestamp = (periodTimestamp: number = 0, nowDate: Date = new Date()): number => {
  const secondsPerDay = 86400;
  const currentSecondTime = Math.floor(nowDate.getTime() / 1000);
  return currentSecondTime + periodTimestamp * secondsPerDay;
};

export const handleErrorTransaction = (error: any) => {
  let finalError = '';
  if (typeof error === 'string' || error instanceof String) {
    finalError = error as string;
  } else {
    if (error?.ex?.message) finalError = String(error.ex.message);
    else if (error?.message) finalError = String(error?.message);
    else finalError = String(error);
  }
  displayToast(TToastType.TX_FAILED, {
    message: finalError,
  });
};

export function roundUnalteredDecimal(num: number, decimal: number = 3): number {
  const valueSplit = num.toString().split('.');
  if (valueSplit.length === 1) {
    return num;
  }
  if (valueSplit.length === 2) {
    const roundValue = valueSplit[0] + '.' + valueSplit[1].slice(0, decimal);
    return Number(roundValue);
  }
}

export const dateFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

export const timeFormat = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  second: '2-digit',
});

export const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

// extend formatToJson
Intl.DateTimeFormat.prototype.formatToJson = function (date: Date | number) {
  const _this = this as Intl.DateTimeFormat;
  return Object.fromEntries(
    _this
      .formatToParts(typeof date === 'number' && date < 1000000000000 ? date * 1000 : date)
      .filter((item) => item.type !== 'literal')
      .map((item) => [item.type, item.value])
  ) as Record<Intl.DateTimeFormatPartTypes, string>;
};

export function formatTVDate(date: Date | number) {
  const obj = dateFormat.formatToJson(date);
  return `${obj.day} ${obj.month} ${obj.year}`;
}

export function formatLeaderboardDate(date: Date | number) {
  const obj = dateFormat.formatToJson(date);
  return `${obj.day} ${obj.month}, ${obj.year}`;
}

export function formatTVTime(date: Date | number) {
  const obj = timeFormat.formatToJson(date);
  return `${obj.hour}:${obj.minute}:${obj.second} ${obj.dayPeriod}`;
}

export function formatTVDateTime(date: Date | number) {
  const obj = dateTimeFormat.formatToJson(date);
  return `${obj.day} ${obj.month} ${obj.year} ${obj.hour}:${obj.minute}:${obj.second}`;
}

export const compareNumber = (coeff: number, number1: DecimalLike, number2: DecimalLike) => {
  return new BigDecimal(coeff).mul(new BigDecimal(number1).sub(number2)).toNumber();
};

export const calcDiffTime = (start: string | Date | number, end: string | Date | number) => {
  return new Date(end).getTime() - new Date(start).getTime();
};

export const formatCountdownTime = (milliseconds: number) => {
  const formatMilliseconds = milliseconds < 0 ? 0 : milliseconds;
  const seconds = Math.floor(formatMilliseconds / TIMER.MILLISECOND);
  const minutes = Math.floor(seconds / TIMER.SECOND);
  const hours = Math.floor(minutes / TIMER.MINUTE);
  const days = Math.floor(hours / TIMER.HOUR);

  const remainingHours = hours % TIMER.HOUR;
  const remainingMinutes = minutes % TIMER.MINUTE;
  const remainingSeconds = seconds % TIMER.SECOND;

  return {
    days: String(days).padStart(2, '0'),
    hours: String(remainingHours).padStart(2, '0'),
    minutes: String(remainingMinutes).padStart(2, '0'),
    seconds: String(remainingSeconds).padStart(2, '0'),
  };
};

export function shortenAddress(address: string) {
  return address.substring(0, 8) + '...' + address.substring(address.length - 7, address.length);
}

export const toFixedIfNecessary = (value: string, dp: number): number => {
  return +parseFloat(value).toFixed(dp);
};

export const isNegative = (number) => number <= 0;

// add `,` when split thounsand value.
export const numberWithCommas = (
  x: number,
  locales: Intl.LocalesArgument = undefined,
  options: Intl.NumberFormatOptions = {}
) => {
  if (isNegative(x)) return '0';
  return x.toLocaleString(locales, options);
};

export const formatDisplayUsdt = (amount: number | string, dp = 2): string => {
  const validatedAmount = validateNumber(amount);
  if (validatedAmount < 1) return `$${toFixedIfNecessary(amount.toString(), 4).toString()}`;

  return `$${numberWithCommas(toFixedIfNecessary(amount.toString(), dp))}`;
};

export const formatDisplayNumber = (amount: number | string, dp = 2): string => {
  const validatedAmount = validateNumber(amount);
  if (validatedAmount < 1) return `${toFixedIfNecessary(amount.toString(), 4).toString()}`;

  return toFixedIfNecessary(amount.toString(), dp).toString();
};

export const getAddress = (addr, prefix: string) => {
  if (!addr) return '';
  const { data } = fromBech32(addr);
  return toBech32(prefix, data);
};

export const getAddressByEIP191 = async (isSwitchWallet?: boolean) => {
  const metamaskOfflineSinger = await MetamaskOfflineSigner.connect(window.ethereum);
  if (!metamaskOfflineSinger) return;
  const accounts = await metamaskOfflineSinger.getAccounts(isSwitchWallet);
  return accounts[0].address;
};

export const keplrCheck = (type: CosmosWalletType) => {
  //@ts-ignore
  return type === 'keplr' && window.keplr && window.keplr.mode === 'extension' && !window?.keplr?.isOwallet;
};

export const owalletCheck = (type: CosmosWalletType) => {
  //@ts-ignore
  return type === 'owallet' && window.owallet?.isOwallet;
};

export const isUnlockMetamask = async (): Promise<boolean> => {
  const ethereum = window.ethereum;
  if (!ethereum || !ethereum.isMetaMask || !ethereum._metamask) return false;
  return await window.ethereum._metamask.isUnlocked();
};

export const getActiveStatusWallet = () => {
  // @ts-ignore
  const isCheckOwallet = window.owallet?.isOwallet;
  const version = window?.keplr?.version;
  const isCheckKeplr = !!version && keplrCheck('keplr');
  const isMetamask = window?.ethereum?.isMetaMask;

  return {
    [WALLET_ENUM.OWALLET]: isCheckOwallet,
    [WALLET_ENUM.KEPLR]: isCheckKeplr,
    [WALLET_ENUM.EIP191]: isMetamask,
  };
};
